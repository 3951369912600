import { OrgRegistrationStatus, PlayerBundleId } from "@ollie-sports/models";

export function getPlayerRegistrationStatus(statuses: OrgRegistrationStatus[]) {
  if (!statuses.length) {
    return OrgRegistrationStatus["no-registration-needed"];
  }

  // If there is one bad, then return bad
  if (statuses.find(a => a === OrgRegistrationStatus.bad)) {
    return OrgRegistrationStatus.bad;
  }

  // If there is one unregistered, then return unregistered
  if (statuses.find(a => a === OrgRegistrationStatus.unregistered)) {
    return OrgRegistrationStatus.unregistered;
  }

  if (statuses.find(a => a === OrgRegistrationStatus.incomplete)) {
    return OrgRegistrationStatus.incomplete;
  }

  if (statuses.find(a => a === OrgRegistrationStatus["team-not-ready"])) {
    return OrgRegistrationStatus["team-not-ready"];
  }

  // No bads or unregistered, so if there is one registered, they are registered
  if (statuses.find(a => a === OrgRegistrationStatus.registered)) {
    return OrgRegistrationStatus.registered;
  }

  return OrgRegistrationStatus["no-registration-needed"];
}
