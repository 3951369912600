import _ from "lodash";
import {
  PlayerBundle,
  PlayerBundle__AccountType,
  PrettyPlayerBundle,
  PlayerBundleId,
  TeamId,
  PlayerId,
  PlayerBundle__LinkedPlayers__item,
  examplePlayerBundleId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { translate } from "@ollie-sports/i18n";
import { BatchTask } from "@ollie-sports/firebase";
import jsonStableStringify from "json-stable-stringify";

export function extractSelfAccountIdFromPlayerBundle(p: { bundle: PlayerBundle }): string | undefined {
  return Object.keys(p.bundle.managingAccounts ?? {}).find(
    id => p.bundle.managingAccounts?.[id]?.type === PlayerBundle__AccountType.selfAthlete
  );
}

export function extractUniqueActiveTeamIdsPlayerBundle(p: { bundle: PlayerBundle }): string[] {
  let teams: string[] = [];
  let activeLinkedPlayers = Object.values(p.bundle.derived.linkedPlayers).forEach(linkedPlayer => {
    if (linkedPlayer.status === "active") {
      teams.push(linkedPlayer.teamId);
    }
  });
  return teams;
}

export function extractUniqueTeamIdsPlayerBundle(p: { bundle: PlayerBundle }): string[] {
  let teamIds: string[] = [];
  let activeLinkedPlayers = Object.values(p.bundle.derived.linkedPlayers).forEach(linkedPlayer => {
    teamIds.push(linkedPlayer.teamId);
  });
  return _.uniq(teamIds);
}

export function extractUniquePlayerIdsFromPlayerBundles(p: {
  currentAccountId: string;
  bundles: (PlayerBundle | PrettyPlayerBundle)[];
}): { playerId: string; type: PlayerBundle__AccountType }[] {
  const bundles = p.bundles.map(a => ("playerBundle" in a ? a.playerBundle : a));
  let result: { playerId: string; type: PlayerBundle__AccountType }[] = [];
  bundles.forEach(bundle => {
    if (bundle.managingAccounts) {
      let playerType: PlayerBundle__AccountType =
        bundle.managingAccounts[p.currentAccountId]?.type || PlayerBundle__AccountType.guardian;
      let playerIds = Object.keys(bundle.derived.linkedPlayers);
      playerIds.forEach(pid => {
        result.push({ playerId: pid, type: playerType });
      });
    }
  });
  return result;
}

export function extractGuardianAccountIdsFromPlayerBundle(p: { bundle: PlayerBundle }): string[] {
  return Object.keys(p.bundle.managingAccounts ?? {}).filter(
    id => p.bundle.managingAccounts?.[id]?.type === PlayerBundle__AccountType.guardian
  );
}

export function extractUniqueGuardianAccountIdsFromPlayerBundles(p: {
  bundles: PlayerBundle[];
  selfAccountId: string;
}): string[] {
  let pendingGuardianAccounts: string[] = [];
  for (let i = 0; i < p.bundles.length; i++) {
    const bundle = p.bundles[i];
    const selfAccountId = extractSelfAccountIdFromPlayerBundle({ bundle });
    if (selfAccountId) {
      continue;
    }
    pendingGuardianAccounts = [...pendingGuardianAccounts, ...extractGuardianAccountIdsFromPlayerBundle({ bundle })];
  }

  return _.uniq(pendingGuardianAccounts);
}

export async function canMergePlayerBundles(p: {
  pb1: PlayerBundle; //Player Bundle to receive all the data
  pb2: PlayerBundle; //Player Bundle to be deleted
  locale: string;
}): Promise<{ status: "allowed" } | { status: "not_allowed"; reason: string }> {
  // SERVER_ONLY_TOGGLE
  let selfAccountId1 = extractSelfAccountIdFromPlayerBundle({ bundle: p.pb1 });
  let selfAccountId2 = extractSelfAccountIdFromPlayerBundle({ bundle: p.pb2 });

  const { appOllieFirestoreV2: h } = getServerHelpers();

  const [account1, account2] = await Promise.all([
    selfAccountId1 ? h.Account.getDoc(selfAccountId1) : null,
    selfAccountId2 ? h.Account.getDoc(selfAccountId2) : null
  ]);

  const pbName1 = `${p.pb1.virtualAthleteAccount.firstName.trim()} ${p.pb1.virtualAthleteAccount.lastName.trim()}`.toLowerCase();
  const pbName2 = `${p.pb2.virtualAthleteAccount.firstName.trim()} ${p.pb2.virtualAthleteAccount.lastName.trim()}`.toLowerCase();

  const accountName1 = account1 ? `${account1.firstName.trim()} ${account1.lastName.trim()}`.toLowerCase() : "";
  const accountName2 = account2 ? `${account2.firstName.trim()} ${account2.lastName.trim()}`.toLowerCase() : "";

  const name1 = accountName1 || pbName1;
  const name2 = accountName2 || pbName2;

  if (name1 !== name2) {
    return {
      status: "not_allowed",
      reason: translate({
        defaultMessage: `The first and last names of the players do not match. If you wish to merge them update their names to match and try again.`,
        serverLocale: p.locale
      })
    };
  }

  if (selfAccountId1 === undefined && selfAccountId2) {
    return {
      status: "not_allowed",
      reason: translate({
        defaultMessage: `The player to be deleted has an associated athlete account! Please delete the player profile WITHOUT an associated athlete account.`,
        serverLocale: p.locale
      })
    };
  }

  if (!!selfAccountId1 && !!selfAccountId2 && selfAccountId1 !== selfAccountId2) {
    return {
      status: "not_allowed",
      reason: translate({
        defaultMessage: `Both players have been claimed by different athlete accounts. Please have one of the athlete unlink their account before trying to merge again.`,
        serverLocale: p.locale
      })
    };
  }

  return { status: "allowed" };
  // SERVER_ONLY_TOGGLE
}

export async function getBatchTasksToUpdateDerivedForPlayerBundle(p: { playerBundleId: PlayerBundleId }): Promise<BatchTask[]> {
  if (p.playerBundleId === examplePlayerBundleId) {
    return [];
  }

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const tasks: BatchTask[] = [];

  let pendingLinkedPlayers: PlayerBundle["derived"]["linkedPlayers"] = {};

  const profile = await h.PlayerBundle.getDoc(p.playerBundleId);
  if (!profile) {
    throw new Error("Unable to find player bundle! " + p.playerBundleId);
  }
  const linkedPlayerObjects = (await h.Player.query({ where: [{ linkedPlayerBundleId: ["==", p.playerBundleId] }] })).docs;
  if (linkedPlayerObjects.length > 0) {
    pendingLinkedPlayers = linkedPlayerObjects.reduce((acc: typeof pendingLinkedPlayers, val) => {
      const temp: PlayerBundle__LinkedPlayers__item = {
        teamId: val.teamId,
        status: val.deletedAtMS === 0 ? "active" : "inactive",
        guestPlayerExirationMS: val.guestPlayerExirationMS ? val.guestPlayerExirationMS : undefined
      };
      acc[val.id] = temp;
      return acc;
    }, {});
  }

  if (jsonStableStringify(profile.derived.linkedPlayers) !== jsonStableStringify(pendingLinkedPlayers)) {
    tasks.push(
      await h.PlayerBundle.setPath(
        {
          id: p.playerBundleId,
          pathObj: { derived: { linkedPlayers: true } },
          value: { derived: { linkedPlayers: pendingLinkedPlayers } }
        },
        { returnBatchTask: true }
      )
    );
  }

  const activeLinkedTeamIds = _(Object.keys(pendingLinkedPlayers))
    .map(pbid => pendingLinkedPlayers[pbid])
    .filter(a => a.status === "active")
    .map(a => a.teamId)
    .value();

  const activeLinkedTeams = await h.Team.getDocs(activeLinkedTeamIds).then(a => _.compact(a));
  const activeLinkedOrgIds = _(activeLinkedTeams)
    .map(a => a.orgId)
    .compact()
    .uniq()
    .value();

  const pendingActiveOrgs = _(activeLinkedOrgIds)
    .keyBy(a => a)
    .mapValues(() => true as const)
    .value();

  if (jsonStableStringify(pendingActiveOrgs) !== jsonStableStringify(profile.derived.activeLinkedOrgs || {})) {
    tasks.push(
      await h.PlayerBundle.setPath(
        {
          id: p.playerBundleId,
          pathObj: { derived: { activeLinkedOrgs: true } },
          value: { derived: { activeLinkedOrgs: pendingActiveOrgs } }
        },
        { returnBatchTask: true }
      )
    );
  }

  return tasks;
}

// i18n certified - complete
