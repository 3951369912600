import { UAParser } from "ua-parser-js";
import stringifyStable from "json-stable-stringify";

export function extractIpAddress(expressRequest: any): string | null {
  let ip = null;

  // Trusting the 'X-Forwarded-For' header: use with caution and only behind trusted proxies
  const forwardedIps = expressRequest.headers["x-forwarded-for"]?.split(",").map((a: string) => a.trim());
  ip = forwardedIps?.[0];

  // Fastly and other CDN headers
  if (!ip) {
    ip =
      expressRequest.headers["fastly-client-ip"] ||
      expressRequest.headers["true-client-ip"] || // Akamai and some other CDNs
      expressRequest.headers["cf-connecting-ip"]; // Cloudflare
  }

  // Standard request properties and their fallbacks
  if (!ip) {
    ip =
      expressRequest.ip ||
      expressRequest.connection?.remoteAddress ||
      expressRequest.socket?.remoteAddress ||
      expressRequest.connection?.socket?.remoteAddress;
  }

  // IPv6 addresses can include a leading "::ffff:" that should be removed for consistency
  if (ip && ip.startsWith("::ffff:")) {
    ip = ip.substr(7);
  }

  return ip;
}

type DeviceTypes = "ios" | "android" | "desktop";

export function getDeviceType(ua: string): DeviceTypes {
  const parser = new UAParser();
  parser.setUA(ua);
  if (parser.getDevice().type === "mobile") {
    if (parser.getOS().name === "iOS") {
      return "ios";
    } else {
      return "android";
    }
  } else {
    return "desktop";
  }
}

export function getDeviceLookupString(p: { userAgent: string }) {
  const { device, engine, os } = new UAParser(p.userAgent || "").getResult(); //These appear to be the only stable parts of the user agent string between the mobile browser and the embedded WebView in the mobile app.

  return stringifyStable({ device, engine, os });
}

// i18n certified - complete
