import {
  Org,
  ORG_PERMISSIONS,
  OrgId,
  Team,
  ORG_CONVERSATION_TYPES,
  AccountId,
  Team__StaffTypes,
  ConversationCustomOrgFilters,
  Team__NonStaffTypes
} from "@ollie-sports/models";
import { teamExtractStaffAccountIdsByTitle } from "./team.compute";
import * as _ from "lodash";
import { ObjectKeys } from "../utils";

export function hasOrgPermission(p: { org: Org; permission: ORG_PERMISSIONS; accountId: string }) {
  const { org, accountId, permission } = p;

  const orgAccount = org.accounts[accountId];

  if (orgAccount && orgAccount.exists) {
    return !!orgAccount.permissions[permission];
  }

  return false;
}

export function isOrgAdmin(adminOrgs: Org[], accountId: string, orgId?: OrgId) {
  return adminOrgs.find(o => o.id === orgId)?.accounts[accountId]?.exists;
}

export function isAnyOrgAdmin(orgs: Org[], accountId: string) {
  return !!orgs.find(o => o.accounts[accountId]?.exists);
}

export function isAnyOrgAdminOnLicensedClub(orgs: Org[], accountId: string) {
  return !!orgs.find(o => o.accounts[accountId]?.exists && !!o.linkedLicenseId);
}

export function getListOfAccountIdsForOrgChatChannel(p: {
  org: Org;
  orgTeams: Team[];
  orgFilters?: ConversationCustomOrgFilters;
  excludeOrgAdmins?: boolean;
}): AccountId[] {
  let filteredTeams = p.orgTeams.filter(t => !t.deletedAtMS);
  const orgAdminAccountIds = Object.keys(p.org.accounts);

  const orgFilters = p.orgFilters ?? {};

  let accountIds: AccountId[] = [];
  const hasTeamsSelected = ObjectKeys(orgFilters.teamIds ?? {}).length > 0;

  if (
    ObjectKeys(orgFilters?.birthYears ?? {}).length +
      ObjectKeys(orgFilters?.genders ?? {}).length +
      ObjectKeys(orgFilters?.roles ?? {}).length +
      ObjectKeys(orgFilters?.orgTeamTagIds ?? {}).length +
      ObjectKeys(orgFilters?.teamIds ?? {}).length ===
    0
  ) {
    const teamAccountIds = _.flatten(filteredTeams.map(team => Object.keys(team.accounts)));
    const participantAccountIds = _.uniq([...teamAccountIds, ...orgAdminAccountIds]);
    return participantAccountIds;
  }
  if (hasTeamsSelected) {
    filteredTeams = filteredTeams.filter(team => orgFilters && orgFilters.teamIds && orgFilters.teamIds[team.id]);
  }
  if (!hasTeamsSelected && ObjectKeys(orgFilters.birthYears ?? {}).length > 0) {
    filteredTeams = filteredTeams.filter(
      team => orgFilters && orgFilters.birthYears && team.birthYear && orgFilters.birthYears[team.birthYear]
    );
  }
  if (!hasTeamsSelected && ObjectKeys(orgFilters.genders ?? {}).length > 0) {
    filteredTeams = filteredTeams.filter(team => orgFilters && orgFilters.genders && orgFilters.genders[team.gender]);
  }
  if (!hasTeamsSelected && ObjectKeys(orgFilters.orgTeamTagIds ?? {}).length > 0) {
    filteredTeams = filteredTeams.filter(
      team =>
        orgFilters &&
        orgFilters.orgTeamTagIds &&
        !!ObjectKeys(orgFilters.orgTeamTagIds).find(orgTeamTagId => !!team.assignedOrgTagIds?.[orgTeamTagId])
    );
  }

  if (ObjectKeys(orgFilters.roles ?? {}).length > 0) {
    const staffKeys = ObjectKeys(Team__StaffTypes);
    const nonStaffKeys = ObjectKeys(Team__NonStaffTypes);
    staffKeys.forEach(staffKey => {
      if (orgFilters && orgFilters.roles && orgFilters.roles[staffKey as Team__StaffTypes]) {
        const staffKeyAccountIds = filteredTeams.reduce((acc, team) => {
          acc = [
            ...acc,
            ...ObjectKeys(team.accounts).filter(accId => {
              return team.accounts[accId]?.staffTitle === staffKey;
            })
          ];
          return acc;
        }, [] as AccountId[]);
        accountIds = [...accountIds, ...staffKeyAccountIds];
      }
    });
    nonStaffKeys.forEach(nonStaffKey => {
      if (orgFilters && orgFilters.roles && orgFilters.roles[nonStaffKey as Team__NonStaffTypes]) {
        const nonStaffKeyAccountIds = filteredTeams.reduce((acc, team) => {
          acc = [
            ...acc,
            ...ObjectKeys(team.accounts).filter(accId => {
              return team.accounts[accId]?.roles[nonStaffKey];
            })
          ];
          return acc;
        }, [] as AccountId[]);
        accountIds = [...accountIds, ...nonStaffKeyAccountIds];
      }
    });
    accountIds = _.uniq([...(p.excludeOrgAdmins ? [] : orgAdminAccountIds), ...accountIds]);
  } else {
    accountIds = _.uniq([
      ...(p.excludeOrgAdmins ? [] : orgAdminAccountIds),
      ...filteredTeams.reduce((acc, team) => {
        acc = [...acc, ...ObjectKeys(team.accounts)];
        return acc;
      }, [] as AccountId[])
    ]);
  }

  return accountIds;
}

export function hasOrgViewFinanacesPermission(p: { org: Org; accountId: string }) {
  return (
    hasOrgPermission({ org: p.org, accountId: p.accountId, permission: ORG_PERMISSIONS.manageFinances }) ||
    hasOrgPermission({ org: p.org, accountId: p.accountId, permission: ORG_PERMISSIONS.viewFinances })
  );
}

export function hasOrgPlayerNotesPermission(p: { org: Org; accountId: string }) {
  return hasOrgPermission({ org: p.org, accountId: p.accountId, permission: ORG_PERMISSIONS.playerNotes });
}

// i18n certified - complete
