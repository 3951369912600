import * as fromPlayerBundle from "./playerBundle.compute";
import * as fromPlayer from "./player.compute";
import * as fromAccount from "./account.compute";
import * as fromTeam from "./team.compute";
import * as fromTrends from "./trends.compute";
import * as fromConversation from "./conversation.compute";
import * as fromCommunicationTag from "./communicationTag";
import * as fromOrg from "./org.compute";
import * as fromCalendarEntry from "./calendarEntry.compute";
import * as fromPostgres from "./postgres.compute";
import * as fromPoll from "./poll.compute";
import * as fromLocation from "./location.compute";
import * as fromEvaluation from "./evaluation.compute";
import * as fromDeferredLink from "./deferredLink.compute";
import * as fromFundraising from "./fundraising.compute";
import * as fromOrgPaymentPlan from "./orgPaymentPlan.compute";
import * as fromOrgRegistrationQuestion from "./orgRegistrationQuestion.compute";
import * as fromOrgSeason from "./orgSeason.compute";
import * as fromOrgInvoice from "./orgInvoice.compute";
import * as fromOrgRegistration from "./orgRegistration.compute";
import * as fromPayments from "./payments.compute";

export const calendarEntry = fromCalendarEntry;
export const communicationTag = fromCommunicationTag;
export const playerBundle = fromPlayerBundle;
export const player = fromPlayer;
export const account = fromAccount;
export const team = fromTeam;
export const conversation = fromConversation;
export const org = fromOrg;
export const trends = fromTrends;
export const poll = fromPoll;
export const location = fromLocation;
export const evaluation = fromEvaluation;
export const deferredLink = fromDeferredLink;
export const fundraising = fromFundraising;
export const orgPaymentPlan = fromOrgPaymentPlan;
export const orgRegistrationQuestion = fromOrgRegistrationQuestion;
export const orgSeason = fromOrgSeason;
export const orgInvoice = fromOrgInvoice;
export const orgRegistration = fromOrgRegistration;
export const postgres = fromPostgres;
export const payments = fromPayments;

//TODO: Don't export * like this. Instead namespace them like we do above...
export * from "./calendarEntry.compute";
export * from "./teamLegacy.compute";
export * from "./org.compute";
export * from "./liveGameTimelineEntry.compute";
export * from "./soccerGame.compute";

// i18n certified - complete
