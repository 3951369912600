import {
  SoccerGame,
  CalendarEntryGameScrimmage,
  CalendarEntryGameScrimmageEndGameDetails,
  PostStartedSoccerGame
} from "@ollie-sports/models";
import moment from "moment";
import { isCalendarEntry2HoursInPast } from "./calendarEntry.compute";

export function hasSoccerGameStarted(soccerGame?: SoccerGame): soccerGame is PostStartedSoccerGame {
  return !!(soccerGame && (soccerGame.gameStage === "started" || soccerGame.gameStage === "ended"));
}

export type LiveGameModeSoccerGameStatus =
  | "unknown"
  | "preLiveGameModeRelease"
  | "soccerGameNotYetCreated"
  | "aboutToStartLive"
  | "live"
  | "completedButNotArchived"
  | "archived"
  | "noStatsRecorded";

export function getLiveGameModeStage(p: {
  calendarEntry?: CalendarEntryGameScrimmage;
  soccerGame?: SoccerGame;
}): LiveGameModeSoccerGameStatus {
  if (!p.calendarEntry && !p.soccerGame) {
    return "unknown";
  }
  if (p.calendarEntry?.startDateTime && moment(p.calendarEntry.arrivalDateTime).valueOf() < 1595311200000) {
    return "preLiveGameModeRelease";
  }
  const isPastEvent = p.calendarEntry ? isCalendarEntry2HoursInPast(p.calendarEntry) : false;
  if (!p.soccerGame) {
    if (isPastEvent) {
      return "noStatsRecorded";
    }
    return "soccerGameNotYetCreated";
  }
  if (p.soccerGame.gameStage === "pre-setup" || p.soccerGame.gameStage === "setup") {
    if (isPastEvent) {
      return "noStatsRecorded";
    }
    return "aboutToStartLive";
  }
  if (p.soccerGame.gameStage === "started" && !isPastEvent) {
    return "live";
  }
  if (p.soccerGame.gameStage === "ended" && !isPastEvent) {
    return "completedButNotArchived";
  }

  return "archived";
}

// i18n certified - complete
