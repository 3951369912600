import {
  Team,
  PlayerBundle,
  AccountId,
  Conversation,
  TEAM_CONVERSATION_TYPES,
  PlayerBundle__AccountType,
  CONVERSATION_TYPES,
  ORG_CONVERSATION_TYPES,
  ConversationCustomOrgFilters,
  __ConversationTeam,
  Account,
  Org,
  Message,
  AccountPrivate,
  TEAM_PERMISSIONS
} from "@ollie-sports/models";
import { extractUniquePlayerIdsFromPlayerBundles } from "./playerBundle.compute";
import _ from "lodash";
import { teamExtractPlayerIdsOnSquad } from "./team.compute";
import { ObjectKeys } from "../utils/object-keys";
import { defineMessage, translate } from "@ollie-sports/i18n";
import { ConversationSimple } from "../constants";
import { combineArrayWithCommasAndAnd } from "../utils";
import { hasPermissionOnTeam } from "./teamLegacy.compute";

export function canAccessSquadConversation(p: {
  team: Team;
  accountId: AccountId;
  teamConversationType: TEAM_CONVERSATION_TYPES;
  playerIdMap: { playerId: string; type: PlayerBundle__AccountType }[];
}): boolean {
  const squadKey = extactSquadKey(p.teamConversationType);

  const isSquadAll = [
    TEAM_CONVERSATION_TYPES.squad_a_all,
    TEAM_CONVERSATION_TYPES.squad_b_all,
    TEAM_CONVERSATION_TYPES.squad_c_all
  ].includes(p.teamConversationType);
  const isSquadPlayers = [
    TEAM_CONVERSATION_TYPES.squad_a_players,
    TEAM_CONVERSATION_TYPES.squad_b_players,
    TEAM_CONVERSATION_TYPES.squad_c_players
  ].includes(p.teamConversationType);
  const isSquadTeam = [
    TEAM_CONVERSATION_TYPES.squad_a_team,
    TEAM_CONVERSATION_TYPES.squad_b_team,
    TEAM_CONVERSATION_TYPES.squad_c_team
  ].includes(p.teamConversationType);

  const playerIdsOnSquad = teamExtractPlayerIdsOnSquad(p.team, squadKey);

  const isStaff: boolean = !!(p.team.accounts[p.accountId]?.additionalPermissions as any)?.[`squad_${squadKey}_staff`];

  const selfAthletePlayerIds = p.playerIdMap
    .filter(pl => pl.type === PlayerBundle__AccountType.selfAthlete)
    .map(pl => pl.playerId);
  const guardianPlayerIds = p.playerIdMap.filter(pl => pl.type === PlayerBundle__AccountType.guardian).map(pl => pl.playerId);

  let isPlayer = _.intersection(selfAthletePlayerIds, playerIdsOnSquad).length > 0;
  let isGuardian = _.intersection(guardianPlayerIds, playerIdsOnSquad).length > 0;

  if (isSquadAll) {
    return isStaff || isPlayer || isGuardian;
  } else if (isSquadPlayers) {
    return isPlayer;
  } else if (isSquadTeam) {
    return isStaff || isPlayer;
  } else {
    console.error("Should never get here in canAccessSquadConversation");
    return false;
  }
}

export function canView(p: {
  accountId: string;
  conversation: Conversation;
  team: Team | undefined | null;
  org: Org | undefined | null;
}): boolean {
  const { conversation: convo } = p;

  if (p.conversation.conversationType === CONVERSATION_TYPES.team && !p.team) {
    throw new Error("Cannot check for access permissions on team conversation without passing a team!");
  }

  const team = p.team!;

  if (p.conversation.conversationType === CONVERSATION_TYPES.team && team.orgId && !p.org) {
    throw new Error("Cannot check for access permissions on team conversation with parent org without passing parent org!");
  }

  if (convo.conversationType == CONVERSATION_TYPES.team) {
    if (team.orgId && p.org?.accounts?.[p.accountId]) {
      return true;
    } else {
      const convoTypeToPermission: Record<TEAM_CONVERSATION_TYPES, TEAM_PERMISSIONS> = {
        all: TEAM_PERMISSIONS.basic,
        alerts: TEAM_PERMISSIONS.basic,
        squad_a_all: TEAM_PERMISSIONS.basic,
        squad_a_players: TEAM_PERMISSIONS.participateTeamAthleteConversations,
        squad_a_team: TEAM_PERMISSIONS.participateTeamTeamConversations,
        squad_b_all: TEAM_PERMISSIONS.basic,
        squad_b_players: TEAM_PERMISSIONS.participateTeamAthleteConversations,
        squad_b_team: TEAM_PERMISSIONS.participateTeamTeamConversations,
        squad_c_all: TEAM_PERMISSIONS.basic,
        squad_c_players: TEAM_PERMISSIONS.participateTeamAthleteConversations,
        squad_c_team: TEAM_PERMISSIONS.participateTeamTeamConversations,
        team: TEAM_PERMISSIONS.participateTeamTeamConversations,
        staff: TEAM_PERMISSIONS.participateTeamStaffConversations,
        players: TEAM_PERMISSIONS.participateTeamAthleteConversations,
        staff_guardians: TEAM_PERMISSIONS.participateTeamStaffGuardiansConversations
      };

      return hasPermissionOnTeam({ accountId: p.accountId, permission: convoTypeToPermission[convo.teamConversationType], team });
    }
  } else if (convo.conversationType === CONVERSATION_TYPES.accounts) {
    return !!convo.accounts[p.accountId];
  } else if (convo.conversationType === CONVERSATION_TYPES.org) {
    return true; //Too expensive to fetch and determine if the user has access to the org conversation. If they're logged in, they have access to view
  } else {
    ((a: never) => {})(convo);
    return false;
  }
}

export function canWriteMessage(p: {
  accountId: string;
  conversation: Conversation;
  team: Team | undefined | null;
  org: Org | undefined | null;
}): boolean {
  if (p.conversation.conversationType == CONVERSATION_TYPES.team) {
    if (!p.team) {
      throw new Error("Cannot check for access permissions on team conversation without passing a team!");
    }

    const team = p.team!;

    if (team.orgId && !p.org) {
      throw new Error("Cannot check for access permissions on team conversation with parent org without passing parent org!");
    }

    if (
      team.orgId &&
      p.org?.accounts?.[p.accountId] &&
      (p.conversation.teamConversationType === TEAM_CONVERSATION_TYPES.all ||
        p.conversation.teamConversationType === TEAM_CONVERSATION_TYPES.alerts ||
        p.conversation.teamConversationType === TEAM_CONVERSATION_TYPES.staff ||
        p.conversation.teamConversationType === TEAM_CONVERSATION_TYPES.staff_guardians)
    ) {
      return true;
    } else if (p.conversation.blockedAccountIds?.[p.accountId]) {
      return false;
    } else if (
      p.conversation.teamConversationType === TEAM_CONVERSATION_TYPES.alerts ||
      p.conversation.disableNonStaffResponses
    ) {
      return !!p.team.accounts[p.accountId]?.roles.staff;
    } else {
      return canView(p);
    }
  } else if (p.conversation.conversationType === CONVERSATION_TYPES.accounts) {
    return canView(p);
  } else if (p.conversation.conversationType === CONVERSATION_TYPES.org) {
    if (!p.org) {
      throw new Error("Unable to check permissions for org chat without access to the org");
    }

    if (p.conversation.blockedAccountIds?.[p.accountId]) {
      return false;
    } else if (p.conversation.disableNonOrgAdminResponses) {
      return !!p.org?.accounts[p.accountId];
    } else {
      return true;
    }
  } else {
    ((a: never) => {})(p.conversation);
    return false;
  }
}

export function isOrgConversation(c: Conversation) {
  return c.conversationType === CONVERSATION_TYPES.org;
}

export function isSquadConversation(c: Conversation) {
  if (c.conversationType === CONVERSATION_TYPES.team) {
    return [
      TEAM_CONVERSATION_TYPES.squad_a_all,
      TEAM_CONVERSATION_TYPES.squad_b_all,
      TEAM_CONVERSATION_TYPES.squad_c_all,
      TEAM_CONVERSATION_TYPES.squad_a_team,
      TEAM_CONVERSATION_TYPES.squad_b_team,
      TEAM_CONVERSATION_TYPES.squad_c_team,
      TEAM_CONVERSATION_TYPES.squad_a_players,
      TEAM_CONVERSATION_TYPES.squad_b_players,
      TEAM_CONVERSATION_TYPES.squad_c_players
    ].includes(c.teamConversationType);
  } else {
    return false;
  }
}

export function extactSquadKey(teamConversationType: TEAM_CONVERSATION_TYPES): "a" | "b" | "c" {
  return [
    TEAM_CONVERSATION_TYPES.squad_a_all,
    TEAM_CONVERSATION_TYPES.squad_a_players,
    TEAM_CONVERSATION_TYPES.squad_a_team
  ].includes(teamConversationType)
    ? "a"
    : [
        TEAM_CONVERSATION_TYPES.squad_b_all,
        TEAM_CONVERSATION_TYPES.squad_b_players,
        TEAM_CONVERSATION_TYPES.squad_b_team
      ].includes(teamConversationType)
    ? "b"
    : [
        TEAM_CONVERSATION_TYPES.squad_c_all,
        TEAM_CONVERSATION_TYPES.squad_c_players,
        TEAM_CONVERSATION_TYPES.squad_c_team
      ].includes(teamConversationType)
    ? "c"
    : "a";
}

export function extactSquadSubset(teamConversationType: TEAM_CONVERSATION_TYPES): "all" | "players" | "team" {
  return [TEAM_CONVERSATION_TYPES.squad_a_all, TEAM_CONVERSATION_TYPES.squad_b_all, TEAM_CONVERSATION_TYPES.squad_c_all].includes(
    teamConversationType
  )
    ? "all"
    : [
        TEAM_CONVERSATION_TYPES.squad_a_players,
        TEAM_CONVERSATION_TYPES.squad_b_players,
        TEAM_CONVERSATION_TYPES.squad_c_players
      ].includes(teamConversationType)
    ? "players"
    : [TEAM_CONVERSATION_TYPES.squad_a_team, TEAM_CONVERSATION_TYPES.squad_b_team, TEAM_CONVERSATION_TYPES.squad_c_team].includes(
        teamConversationType
      )
    ? "team"
    : "all";
}

export function isOrgAllConversation(conversation: Conversation | ConversationSimple) {
  return (
    conversation.conversationType === CONVERSATION_TYPES.org &&
    ObjectKeys(conversation.orgFilters ?? {}).length === 0 &&
    conversation.isDefaultOrgConversation
  );
}

export function getOrgFiltersForDefaultChannels(orgConversationType: ORG_CONVERSATION_TYPES): ConversationCustomOrgFilters {
  switch (orgConversationType) {
    case ORG_CONVERSATION_TYPES.all:
      return {};
    case ORG_CONVERSATION_TYPES.admins:
      return {
        roles: {
          orgAdmin: true
        }
      };
    case ORG_CONVERSATION_TYPES.coaches:
      return {
        roles: {
          headCoach: true,
          assistantCoach: true
        }
      };
    case ORG_CONVERSATION_TYPES.teamAdmins:
      return {
        roles: {
          teamAdmin: true
        }
      };
  }
}

export function getConversationTitle(p: {
  conversation: Conversation;
  maxAccountsInTitle: number;
  selfAccountId: string;
  accounts: Account[];
  userTeams: Team[];
  userOrgs: Org[];
  locale: string;
}) {
  const { conversation, accounts } = p;
  if (conversation.conversationType === CONVERSATION_TYPES.accounts) {
    if (conversation.title) {
      return conversation.title;
    }
    const sortedAccounts = _.sortBy(
      accounts.filter(a => a.id !== p.selfAccountId),
      "firstName"
    ).slice(0, p.maxAccountsInTitle);

    if (sortedAccounts.length === 1) {
      return sortedAccounts[0]!.firstName.trim() + " " + sortedAccounts[0]!.lastName.trim();
    } else {
      let title = sortedAccounts.map(a => a.firstName.trim()).join(", ");
      const numUnusedAccounts = Object.keys(conversation.accounts).length - 1 - sortedAccounts.length;
      if (numUnusedAccounts > 0) {
        title += ", +" + numUnusedAccounts;
      }

      return title;
    }
  } else if (conversation.conversationType === CONVERSATION_TYPES.team) {
    const convo = conversation;
    const thisTeam = p.userTeams.find(t => t.id === convo.teamId);
    if (!thisTeam) {
      return "";
    }

    const squadAll = defineMessage({
      serverLocale: p.locale,
      defaultMessage: "@{squad} all",
      description: "The tag for conversations that go to ALL users who belong to a specific squad"
    });

    const squadPlayers = defineMessage({
      serverLocale: p.locale,
      defaultMessage: "@{squad} players",
      description: "The tag for conversations that go to just the players on a specific squad"
    });

    const squadStaffAndPlayers = defineMessage({
      serverLocale: p.locale,
      defaultMessage: "@{squad} staff/players",
      description: "The tag for conversations that go to just staff and players who belong to a specific squad"
    });

    const prettySquads = {
      a: getPrettySquadChannelName(thisTeam.squads?.a?.name || ""),
      b: getPrettySquadChannelName(thisTeam.squads?.b?.name || ""),
      c: getPrettySquadChannelName(thisTeam.squads?.c?.name || "")
    };

    const teamConvosToPrettyStrings: Record<TEAM_CONVERSATION_TYPES, string> = {
      [TEAM_CONVERSATION_TYPES.all]: translate({
        serverLocale: p.locale,
        defaultMessage: "@all",
        description: "The prefix for the team channel that should go out to ALL team members"
      }),
      [TEAM_CONVERSATION_TYPES.alerts]: translate({
        serverLocale: p.locale,
        defaultMessage: "@alerts",
        description: "The prefix for the team channel that should go out to ALL team members for alerts"
      }),
      [TEAM_CONVERSATION_TYPES.staff]: translate({
        serverLocale: p.locale,
        defaultMessage: "@staff",
        description: "The prefix for the team channel that should go out to just staff"
      }),
      [TEAM_CONVERSATION_TYPES.staff_guardians]: translate({
        serverLocale: p.locale,
        defaultMessage: "@staff/guardians",
        description: "The prefix for the team channel that should go out to staff and guardians"
      }),
      [TEAM_CONVERSATION_TYPES.players]: translate({
        serverLocale: p.locale,
        defaultMessage: "@players",
        description: "The prefix for the team channel that should go out to just players"
      }),
      [TEAM_CONVERSATION_TYPES.team]: translate({
        serverLocale: p.locale,
        defaultMessage: "@staff/players",
        description: "The prefix for the team channel that should go out to just staff and players"
      }),
      [TEAM_CONVERSATION_TYPES.squad_a_all]: translate(squadAll, { squad: prettySquads.a }),
      [TEAM_CONVERSATION_TYPES.squad_a_players]: translate(squadPlayers, { squad: prettySquads.a }),
      [TEAM_CONVERSATION_TYPES.squad_a_team]: translate(squadStaffAndPlayers, { squad: prettySquads.a }),
      [TEAM_CONVERSATION_TYPES.squad_b_all]: translate(squadAll, { squad: prettySquads.b }),
      [TEAM_CONVERSATION_TYPES.squad_b_players]: translate(squadPlayers, { squad: prettySquads.b }),
      [TEAM_CONVERSATION_TYPES.squad_b_team]: translate(squadStaffAndPlayers, { squad: prettySquads.b }),
      [TEAM_CONVERSATION_TYPES.squad_c_all]: translate(squadAll, { squad: prettySquads.c }),
      [TEAM_CONVERSATION_TYPES.squad_c_players]: translate(squadPlayers, { squad: prettySquads.c }),
      [TEAM_CONVERSATION_TYPES.squad_c_team]: translate(squadStaffAndPlayers, { squad: prettySquads.c })
    };

    return thisTeam.shortName + " " + teamConvosToPrettyStrings[convo.teamConversationType || TEAM_CONVERSATION_TYPES.all];
  } else if (conversation.conversationType === CONVERSATION_TYPES.org) {
    const orgId = conversation.orgId;
    const thisOrg = p.userOrgs.find(org => org.id === orgId);
    const orgShortName = thisOrg ? thisOrg.shortName : "Organization";
    return `${orgShortName} - ${conversation.title}`;
  }
  return "";
}

export function getPrettySquadChannelName(name: string) {
  return name
    .toLowerCase()
    .trim()
    .replace(/ /g, "-")
    .replace(/[^a-z0-9/-]/g, "");
}

export function getLastMessageText(p: { lastMessage: Message; lastMessageAccountFirstName: string | null; locale: string }) {
  return p.lastMessage?.text
    ? p.lastMessage.text
    : p.lastMessage?.pollId
    ? p.lastMessageAccountFirstName
      ? translate(
          { defaultMessage: "{firstName} posted a poll", serverLocale: p.locale },
          { firstName: p.lastMessageAccountFirstName }
        )
      : translate({ defaultMessage: "A poll was posted", serverLocale: p.locale })
    : p.lastMessage?.imageUri
    ? p.lastMessageAccountFirstName
      ? translate(
          { defaultMessage: "{firstName} posted an image", serverLocale: p.locale },
          { firstName: p.lastMessageAccountFirstName }
        )
      : translate({ defaultMessage: "An image was uploaded", serverLocale: p.locale })
    : p.lastMessage?.videoUri
    ? p.lastMessageAccountFirstName
      ? translate(
          { defaultMessage: "{firstName} uploaded a video", serverLocale: p.locale },
          { firstName: p.lastMessageAccountFirstName }
        )
      : translate({ defaultMessage: "A video was uploaded", serverLocale: p.locale })
    : p.lastMessage.automatedMessageDetails
    ? getAutomatedMessageText(p.lastMessage, p.locale).replace(/\*/g, "")
    : "";
}

export function getAutomatedMessageText(message: Message, locale: string) {
  if (message.automatedMessageText) {
    // This is the legacy case
    return message.automatedMessageText;
  }
  if (!message.automatedMessageDetails) {
    return "";
  }
  const { automatedMessageDetails } = message;

  switch (automatedMessageDetails.type) {
    case "addedPeople":
      return translate(
        { defaultMessage: "{name} added {peopleString}", serverLocale: locale },
        {
          name: automatedMessageDetails.accountName,
          peopleString: combineArrayWithCommasAndAnd(Object.keys(automatedMessageDetails.newAccountNames ?? {}), locale)
        }
      );
    case "removedPerson":
      return translate(
        { defaultMessage: "{name} removed {personString}", serverLocale: locale },
        {
          name: automatedMessageDetails.accountName,
          personString: automatedMessageDetails.removedAccountName
        }
      );
    case "leftConversation":
      return translate({ defaultMessage: "{name} left.", serverLocale: locale }, { name: automatedMessageDetails.accountName });
    case "conversationTitleChange":
      if (automatedMessageDetails.newConversationName) {
        if (automatedMessageDetails.accountName) {
          return translate(
            { defaultMessage: "{name} changed the conversation title to {title}.", serverLocale: locale },
            { name: automatedMessageDetails.accountName, title: `*${automatedMessageDetails.newConversationName}*` }
          );
        } else {
          return translate(
            { defaultMessage: "Someone changed the conversation title to {title}.", serverLocale: locale },
            { title: `*${automatedMessageDetails.newConversationName}*` }
          );
        }
      } else {
        if (automatedMessageDetails.accountName) {
          return translate(
            { defaultMessage: "{name} changed the conversation title.", serverLocale: locale },
            { name: automatedMessageDetails.accountName }
          );
        } else {
          return translate({ defaultMessage: "Someone changed the conversation title.", serverLocale: locale });
        }
      }
  }
}

export function sortAndFilterConversationsForUser(
  conversations: Conversation[],
  accountPrivate: AccountPrivate | undefined | null,
  includeArchivedAndEmptyConvos: boolean
): Conversation[];
export function sortAndFilterConversationsForUser(
  conversations: ConversationSimple[],
  accountPrivate: AccountPrivate | undefined | null,
  includeArchivedAndEmptyConvos: boolean
): ConversationSimple[];
export function sortAndFilterConversationsForUser(
  conversations: (ConversationSimple | Conversation)[],
  accountPrivate: AccountPrivate | undefined | null,
  includeArchivedAndEmptyConvos: boolean
): (ConversationSimple | Conversation)[] {
  return _(conversations)
    .filter(c => {
      const archiveDateMS = accountPrivate?.settings?.conversations?.[c.id]?.archivedMS;
      const isArchived = !!c.updatedAtMS && !!archiveDateMS && archiveDateMS > c.updatedAtMS;
      const isEmptyConvo = !c.lastMessageId;
      const isArchivedOrEmpty = isArchived || isEmptyConvo;
      return isArchivedOrEmpty ? includeArchivedAndEmptyConvos : true;
    })
    .orderBy(
      [c => Number(accountPrivate?.settings?.conversations?.[c.id]?.pinned ?? 0), c => c.updatedAtMS || 0],
      ["desc", "desc"]
    )
    .value();
}

export function computeOneOnOneAccountIconInfo(convoAccounts: Account[]): ConversationSimple["oneOnOneAccountIconInfo"] {
  const initials =
    convoAccounts.length === 1
      ? [convoAccounts[0].firstName, convoAccounts[0].lastName].map(a => a.slice(0, 1)).join("")
      : undefined;

  const photoUrl = convoAccounts.length === 1 ? convoAccounts[0].profileImageUriSmall : undefined;

  const oneOnOneStr = photoUrl ?? initials;

  return oneOnOneStr ? { type: photoUrl ? "photo" : "initials", value: oneOnOneStr } : undefined;
}
