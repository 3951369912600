import { AccountId, Poll } from "@ollie-sports/models";
import _ from "lodash";
import { ObjectKeys, ObjectValues } from "../utils/object-keys";

export function getAllPollStatusDetails(p: { poll: Poll; selfAccountId: AccountId }) {
  const isPollClosed = Date.now() > p.poll.endDateTimeMS;
  const hasVoted = ObjectKeys(p.poll.votesByAccountId?.[p.selfAccountId] ?? {}).length;
  const isPollCreator = p.poll.creatorAccountId === p.selfAccountId;
  const isPollPublic = p.poll.isPublicPoll;

  return { isPollClosed, hasVoted, isPollCreator, isPollPublic };
}

export function getPollVoteCountByOption(p: { poll: Poll }) {
  const voteCountByOption: Record<number, number> = ObjectKeys(p.poll.options).reduce((acc, val) => {
    acc[val] = 0;
    return acc;
  }, {} as Record<number, number>);

  ObjectKeys(p.poll.votesByAccountId ?? {}).forEach(accountId => {
    ObjectKeys(p.poll.votesByAccountId?.[accountId] ?? {}).forEach(optionNumber => {
      voteCountByOption[optionNumber]++;
    });
  });

  return voteCountByOption;
}

export function getPollLeadingResultsAndMyVotes(p: { poll: Poll; selfAccountId: AccountId }) {
  let leadingPollResults: { optionNumber: number; votes: number; text: string }[] = [];
  let myVotes: { optionNumber: number; text: string }[] = [];

  const voteCountByOption = getPollVoteCountByOption({ poll: p.poll });
  const maxVoteCountForAnOption = _.max(ObjectValues(voteCountByOption));
  if (maxVoteCountForAnOption && maxVoteCountForAnOption > 0) {
    const leadingOptions = ObjectKeys(voteCountByOption).forEach(optionNumber => {
      const numVotes = voteCountByOption[optionNumber];
      if (numVotes === maxVoteCountForAnOption) {
        leadingPollResults.push({
          optionNumber,
          votes: numVotes,
          text: p.poll.options[optionNumber] ?? ""
        });
      }
    });
  }

  ObjectKeys(p.poll.votesByAccountId?.[p.selfAccountId] ?? {}).forEach(optionNumber => {
    myVotes.push({
      optionNumber,
      text: p.poll.options[optionNumber] ?? ""
    });
  });

  return { leadingPollResults, myVotes };
}

// i18n certified - complete
