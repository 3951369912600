import {
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  OrgInvoice__Manual,
  OrgInvoice__ManualPaymentPlanInstallment,
  OrgPayment,
  OrgPaymentId
} from "@ollie-sports/models";
import _ from "lodash";
import { DistributiveOmit, filterOrgPaymentInvoices, isChildOrgInvoice, isParentOrgInvoice } from "../utils";
import { OrgInvoiceGroup, OrgInvoiceGroupStatus } from "../constants";

export function groupAndCategorizeInvoices(invoices: OrgInvoice[]): OrgInvoiceGroup[] {
  if (!invoices.find(a => a.type === OrgInvoiceTypes.manual || a.type === OrgInvoiceTypes.registration)) {
    throw new Error("Must have at least one manual or registration invoice!");
  }

  return _(invoices)
    .groupBy(a => (a.type === OrgInvoiceTypes.manual || a.type === OrgInvoiceTypes.registration ? a.id : a.parentOrgInvoiceId))
    .mapValues(a => {
      const parent = a.find(b => b.type === OrgInvoiceTypes.manual || b.type === OrgInvoiceTypes.registration)!;
      const group = {
        type: parent.type === OrgInvoiceTypes.manual ? ("manual" as const) : ("registration" as const),
        parent: parent as any,
        children: a.filter(
          b =>
            b.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
            b.type === OrgInvoiceTypes.registrationPaymentPlanInstallment
        ) as any[]
      };

      return { ...group, status: getInvoiceGroupStatus(group) };
    })
    .values()
    .compact()
    .value();
}

export function getInvoiceGroupStatus(a: DistributiveOmit<OrgInvoiceGroup, "status">): OrgInvoiceGroupStatus {
  if (a.parent.thisInvoicePaidInFullDateMS && a.children.every(b => b.thisInvoicePaidInFullDateMS)) {
    return "completed";
  } else if (a.parent.thisInvoicePaidInFullDateMS && !a.children.every(b => b.thisInvoicePaidInFullDateMS)) {
    if (a.children.some(b => !b.thisInvoicePaidInFullDateMS && b.dueDateMS < Date.now())) {
      return "inProgressPastDue";
    } else {
      return "inProgress";
    }
  } else {
    if (a.parent.dueDateMS > Date.now()) {
      return "notStarted";
    } else {
      return "notStartedPastDue";
    }
  }
}

export function groupOrgInvoicesUnderParentWithAssociatedOrgPayments(p: {
  orgInvoices: OrgInvoice[];
  orgPayments: OrgPayment[];
}) {
  const orgInvoiceParents = _.orderBy(_.uniq(p.orgInvoices.filter(oi => isParentOrgInvoice(oi))), a => a.createdAtMS, "desc");

  return orgInvoiceParents.reduce(
    (acc, orgInvoiceParent) => {
      const orgInvoiceChildren = p.orgInvoices.filter(
        oi =>
          (oi.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
            oi.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
          oi.parentOrgInvoiceId === orgInvoiceParent.id
      ) as OrgInvoiceChild[];
      const allOrgInvoiceIds = [orgInvoiceParent.id, ...orgInvoiceChildren.map(oi => oi.id)];
      const orgPayments = filterOrgPaymentInvoices(p.orgPayments).filter(op => allOrgInvoiceIds.includes(op.invoiceId));
      acc.push({
        orgInvoiceParent: orgInvoiceParent as OrgInvoiceParent,
        orgInvoiceChildren,
        orgPayments
      });
      return acc;
    },
    [] as {
      orgInvoiceParent: OrgInvoiceParent;
      orgInvoiceChildren: OrgInvoiceChild[];
      orgPayments: OrgPayment[];
    }[]
  );
}
