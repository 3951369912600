import { dateFormatters, translate } from "@ollie-sports/i18n";
import {
  PlayerBundleProfileFilterSettings,
  PrettyPlayerBundle,
  Team,
  TeamIdWithSquadProfileFilterSettings
} from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment";
import { compute } from "..";
import { common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad, common__generateTeamIdWithSquad } from "../api";
import { ObjectKeys } from "../utils/object-keys";
import { isPartOfSquad } from "./team.compute";

export function getPlayerBundleAndTeamProfiles(p: {
  managedPrettyPlayerBundles: PrettyPlayerBundle[];
  teams: Team[];
  accountId: string;
  includeInactive?: boolean;
}) {
  const playerBundleProfiles: {
    type: "player";
    prettyPlayerBundle: PrettyPlayerBundle;
    isActive: boolean;
    name: string;
  }[] = p.managedPrettyPlayerBundles.map(pl => ({
    type: "player",
    prettyPlayerBundle: pl,
    isActive: !!ObjectKeys(pl.playerBundle.derived.linkedPlayers).find(
      playerId => pl.playerBundle.derived.linkedPlayers[playerId]?.status === "active"
    ),
    name: `${pl.derived.accountInfo.firstName} ${pl.derived.accountInfo.lastName}`
  }));

  const managedPlayerIds = p.managedPrettyPlayerBundles.reduce((acc, bundle) => {
    ObjectKeys(bundle.playerBundle.derived.linkedPlayers).forEach(playerId => {
      acc.push(playerId);
    });
    return acc;
  }, [] as string[]);

  const teamProfiles: { type: "team"; team: Team; teamIdWithSquad: string; isActive: boolean; name: string }[] = [];

  p.teams.forEach(team => {
    let teamIdWithSquad = common__generateTeamIdWithSquad(team.id);
    teamProfiles.push({
      teamIdWithSquad,
      type: "team",
      isActive: true,
      team,
      name: common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad(teamIdWithSquad, team, true)
    });
    if (team.enabledFeatures?.squads) {
      compute.team.teamExtractSquadKeys(team.squads).forEach(squad => {
        if (
          isPartOfSquad({
            accountId: p.accountId,
            team,
            squad,
            managedPlayerIds
          })
        ) {
          teamIdWithSquad = common__generateTeamIdWithSquad(team.id, squad);
          teamProfiles.push({
            teamIdWithSquad,
            type: "team",
            isActive: true,
            team,
            name: common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad(teamIdWithSquad, team, true)
          });
        }
      });
    }
  });

  const profiles = [..._.orderBy(playerBundleProfiles, "name"), ..._.orderBy(teamProfiles, "name")];
  return profiles;
}

export function getPlayerBundleProfileCardFiltersMessage(p: { filters?: PlayerBundleProfileFilterSettings; locale: string }) {
  let filterMessage = translate({ defaultMessage: "Last 365 Days", serverLocale: p.locale });
  if (p.filters) {
    if (p.filters.startDateMS && p.filters.endDateMS) {
      filterMessage = `${dateFormatters.mmm_d_yyyy(p.filters.startDateMS, p.locale)} - ${dateFormatters.mmm_d_yyyy(
        p.filters.endDateMS,
        p.locale
      )}`;
    } else if (p.filters.startDateMS) {
      filterMessage = `${dateFormatters.mmm_d_yyyy(p.filters.startDateMS, p.locale)} - ${translate({
        defaultMessage: "Present",
        description: "present for a date, as in the present day.",
        serverLocale: p.locale
      })}`;
    } else if (p.filters.endDateMS) {
      filterMessage = translate(
        { defaultMessage: `Before {endDate}`, serverLocale: p.locale },
        { endDate: dateFormatters.mmm_d_yyyy(p.filters.endDateMS, p.locale) }
      );
    }
    if (p.filters.matchType || p.filters.teamIdsWithSquad) {
      filterMessage = filterMessage + ` + ${translate({ defaultMessage: "Other Filters", serverLocale: p.locale })}`;
    }
  }
  return filterMessage;
}
export function getTeamProfileCardFiltersMessage(p: { filters?: TeamIdWithSquadProfileFilterSettings; locale: string }) {
  let filterMessage = translate({ defaultMessage: "Last 365 Days", serverLocale: p.locale });
  if (p.filters) {
    if (p.filters.startDateMS && p.filters.endDateMS) {
      filterMessage = `${dateFormatters.mmm_d_yyyy(p.filters.startDateMS, p.locale)} - ${dateFormatters.mmm_d_yyyy(
        p.filters.endDateMS,
        p.locale
      )}`;
    } else if (p.filters.startDateMS) {
      filterMessage = `${dateFormatters.mmm_d_yyyy(p.filters.startDateMS, p.locale)} - ${translate({
        defaultMessage: "Present",
        description: "present for a date, as in the present day.",
        serverLocale: p.locale
      })}`;
    } else if (p.filters.endDateMS) {
      filterMessage = translate(
        { defaultMessage: `Before {endDate}`, serverLocale: p.locale },
        { endDate: dateFormatters.mmm_d_yyyy(p.filters.endDateMS, p.locale) }
      );
    }
    if (p.filters.matchType) {
      filterMessage = filterMessage + ` + ${translate({ defaultMessage: "Other Filters", serverLocale: p.locale })}`;
    }
  }
  return filterMessage;
}

// i18n certified - complete
