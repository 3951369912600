import { OrgRegistrationPackage, OrgRegistrationQuestionGrouping, OrgRegistrationQuestion } from "@ollie-sports/models";
import _ from "lodash";
import { ObjectKeys } from "../utils";

export function getOrgQuestionIdsSortedForOrgRegistrationPackage(p: {
  orgRegistrationPackage: OrgRegistrationPackage;
  orgRegistrationQuestionGroupings: OrgRegistrationQuestionGrouping[];
  orgRegistrationQuestions: OrgRegistrationQuestion[];
}) {
  const questionsArrayFromPackage = ObjectKeys(p.orgRegistrationPackage.questionIds ?? {}).reduce((acc, val) => {
    const questionData = p.orgRegistrationPackage.questionIds?.[val];
    if (questionData) {
      acc.push({
        type: "question",
        id: questionData.id,
        sortOrder: questionData.sortOrder
      });
    }
    return acc;
  }, [] as { type: "question"; sortOrder: number; id: string }[]);
  const questionsGroupingsArrayFromPackage = ObjectKeys(p.orgRegistrationPackage.questionGroupingIds ?? {}).reduce((acc, val) => {
    const questionGroupingData = p.orgRegistrationPackage.questionGroupingIds?.[val];
    if (questionGroupingData) {
      acc.push({
        type: "grouping",
        id: questionGroupingData.id,
        sortOrder: questionGroupingData.sortOrder
      });
    }
    return acc;
  }, [] as { type: "grouping"; sortOrder: number; id: string }[]);
  const combinedArray = [...questionsArrayFromPackage, ...questionsGroupingsArrayFromPackage];
  const sortedArray = _.orderBy(combinedArray, a => a.sortOrder, "asc");

  const sortedQuestionIds = sortedArray.reduce((acc, val) => {
    if (val.type === "question") {
      acc.push(val.id);
    } else {
      const questionGrouping = p.orgRegistrationQuestionGroupings.find(g => g.id === val.id);
      if (questionGrouping) {
        const allQuestionIdsInGroupingWithSortOrder = ObjectKeys(questionGrouping.questionIds).reduce((acc2, val2) => {
          const questionData = questionGrouping.questionIds[val2];
          acc2.push({
            id: questionData.id,
            sortOrder: questionData.sortOrder
          });
          return acc2;
        }, [] as { id: string; sortOrder: number }[]);
        const allQuestionIdsInGroupSorted = _.orderBy(allQuestionIdsInGroupingWithSortOrder, a => a.sortOrder, "asc");
        acc = [...acc, ...allQuestionIdsInGroupSorted.map(a => a.id)];
      }
    }
    return acc;
  }, [] as string[]);

  return sortedQuestionIds;
}
