import { Team__StaffTypes, Account, Team, Org } from "@ollie-sports/models";
import _ from "lodash";
import { team__server__getAllTeamsRelevantToUser } from "../../api/team/team__getAllTeamsRelevantToUser";
import { getUniversalHelpers } from "../../helpers";
import { computeTeamIdToHighestRankingStaff, extractHighestRankingStaff } from "../team.compute";
import { UserTeamContextInfo } from "./constants";

export async function getUserTeamContextInfo(userAccountId: string): Promise<UserTeamContextInfo> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const { orgAdminTeams, userTeams, adminOrgs } = await team__server__getAllTeamsRelevantToUser({ userAccountId });

  const allTeams: Team[] = _.uniqBy(userTeams.concat(_.flatten(Object.values(orgAdminTeams).map(a => a.orgTeams))), a => a.id);

  const topStaffInfoByTeamId = await computeTeamIdToHighestRankingStaff({
    teams: allTeams,
    getAccounts: accountIds => h.Account.getDocs(accountIds).then(vals => vals.filter((a): a is Account => !!a))
  });

  return {
    orgAdminTeams,
    topStaffInfoByTeamId,
    userTeams,
    adminOrgs
  };
}

// i18n certified - complete
