import { AccountId, Evaluation, EvaluationTemplate } from "@ollie-sports/models";

export function getPreviewEvaluationFromTemplate(p: { template: EvaluationTemplate; selfAccountId: AccountId }): Evaluation {
  return {
    type: "player",
    evaluatorId: p.selfAccountId,
    id: "preview",
    items: p.template.items,
    playerId: "preview",
    ratingScale: p.template.ratingScale,
    teamId: "preview",
    title: p.template.title
  };
}

// i18n certified - complete
