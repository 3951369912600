import {
  Account,
  CommunicationTag__Constants__AccountFilter,
  CommunicationTag__Constants__AccountFilter__Squad,
  CommunicationTag__Constants__AccountFilter__TeamRole,
  CommunicationTag__Constants__GenericFilter,
  CommunicationTag__Constants__TeamFilter,
  CommunicationTag__Constants__TeamFilter__BirthYear,
  CommunicationTag__Constants__TeamFilter__Gender,
  CommunicationTag__Constants__TeamRolesEnum,
  CommunicationTag__Org,
  CommunicationTag__OrgAdmins,
  GENDERS,
  Org,
  Team,
  TEAM_TYPES,
  Team__StaffTypes
} from "@ollie-sports/models";
import { CommunicationTag, CommunicationTag__Team, CommunicationTag__MinimalBase } from "@ollie-sports/models";
import _ from "lodash";
import { assertUnreachable } from "../../internal-utils/typescript-utils";
import { getAccountIdsMatchingTag, getGettersFromUserContext } from "./getAccountIdsMatchingTag.compute";
import { UserTeamContextInfo } from "./constants";
import { DistributiveOmit, ObjectKeys } from "../../utils/object-keys";
import { compute } from "../..";

type BaseTag = DistributiveOmit<CommunicationTag, keyof CommunicationTag__MinimalBase>;

// TODO Translate for web
export async function getAllPossibleTags(p: { userAccountId: string } & UserTeamContextInfo): Promise<CommunicationTag[]> {
  const rolesArr = ObjectKeys(CommunicationTag__Constants__TeamRolesEnum).map(
    role => CommunicationTag__Constants__TeamRolesEnum[role]
  );
  const baseTags: BaseTag[] = [];

  const allTeams: Team[] = _.uniqBy(
    p.userTeams.concat(_.flatten(Object.values(p.orgAdminTeams).map(a => a.orgTeams))),
    a => a.id
  );

  /**
   *
   *  Simple team tags
   *
   * **/
  allTeams.forEach(team => {
    const comboBuckets: (CommunicationTag__Constants__AccountFilter | null)[][] = [
      [...extractSquads([team]), null].map(squad => (squad ? { filterType: "squad", squad } : null)),
      [
        ...rolesArr.filter(
          r =>
            ![
              //No need to have these uber specific tags on team tags. They'll just clutter the search results
              CommunicationTag__Constants__TeamRolesEnum.headCoach,
              CommunicationTag__Constants__TeamRolesEnum.teamAdmins,
              CommunicationTag__Constants__TeamRolesEnum.coaches
            ].includes(r)
        ),
        null
      ].map(role => (role ? { filterType: "teamRole", role } : null))
    ];

    allCombinations(comboBuckets).forEach(allFilters => {
      const appliedFilters = allFilters.filter((a): a is CommunicationTag__Constants__AccountFilter => !!a);

      const topStaffInfo = p.topStaffInfoByTeamId[team.id];

      const prettyFilters = getPrettyFilters(appliedFilters);
      const thisTag: Omit<CommunicationTag__Team, keyof CommunicationTag__MinimalBase> = {
        tagType: "teamTag",
        prettyFilters,
        entityName: team.name,
        gender: team.gender,
        birthYear: team.birthYear,
        topStaffInfo: topStaffInfo
          ? {
              fullName: `${topStaffInfo.account.firstName} ${topStaffInfo.account.lastName}`,
              role: topStaffInfo.role
            }
          : undefined,
        teamId: team.id
      };

      appliedFilters.forEach(filter => {
        thisTag.accountFilters = thisTag.accountFilters || [];
        thisTag.accountFilters.push(filter);
      });

      baseTags.push(thisTag);
    });
  });

  /**
   *
   *  Org Tags
   *
   * **/

  Object.keys(p.orgAdminTeams).forEach(orgId => {
    const { org, orgTeams } = p.orgAdminTeams[orgId];

    const genders = _(orgTeams)
      .map(t => t.gender)
      .uniq()
      .valueOf();
    const birthYears = _(orgTeams)
      .map(t => t.birthYear)
      .filter((a): a is string => !!a)
      .uniq()
      .valueOf();

    const combinationBuckets: (CommunicationTag__Constants__GenericFilter | null)[][] = [
      [...genders, null].map(gender => (gender ? { filterType: "gender", gender } : null)),
      [...birthYears, null].map(birthYear => (birthYear ? { filterType: "birthYear", birthYear } : null)),
      [...rolesArr, null].map(role => (role ? { filterType: "teamRole", role } : null))
    ];

    const combinations = allCombinations(combinationBuckets);

    combinations.forEach(filters => {
      const filtersToApply = filters.filter((a): a is CommunicationTag__Constants__GenericFilter => !!a);

      const prettyFilters = getPrettyFilters(filtersToApply);
      const thisTag: Omit<CommunicationTag__Org, keyof CommunicationTag__MinimalBase> = {
        tagType: "orgTag",
        orgId,
        entityName: org.name,
        prettyFilters
      };

      filtersToApply.forEach(filter => {
        switch (filter.filterType) {
          case "birthYear":
          case "gender":
            thisTag.teamFilters = thisTag.teamFilters || [];
            thisTag.teamFilters.push(filter);
            break;
          case "squad":
          case "teamRole":
            thisTag.accountFilters = thisTag.accountFilters || [];
            thisTag.accountFilters.push(filter);
            break;
        }
      });

      baseTags.push(thisTag);
    });
  });

  /**
   *
   *  Org Admins Tag(s)
   *
   * **/
  p.adminOrgs.forEach(org => {
    const orgAdminsTag: Omit<CommunicationTag__OrgAdmins, keyof CommunicationTag__MinimalBase> = {
      orgId: org.id,
      entityName: org.name,
      orgAdminsFilterType: "all",
      // TODO Translate web
      prettyFilters: `All ${org.defaultTeamType == TEAM_TYPES.club ? "Club" : "Organization"} Admins`,
      tagType: "orgAdminsTag"
    };

    baseTags.push(orgAdminsTag);
  });

  /**
   *
   *  Finalize tags...
   *
   * **/
  const info = await Promise.all(
    baseTags.map(async (baseTag): Promise<CommunicationTag> => {
      return {
        ...baseTag,
        estimatedNumAccounts: (
          await getAccountIdsMatchingTag({ tag: baseTag, userAccountId: p.userAccountId, ...getGettersFromUserContext(p) })
        ).length,
        lookupKey: getTagLookupKey(baseTag),
        lookupGroup: getTagLookupGroup(baseTag),
        prettyName: `${baseTag.entityName} - ${baseTag.prettyFilters}`
      };
    })
  );

  return _(info)
    .filter(tag => !!tag.estimatedNumAccounts)
    .orderBy([a => a.lookupGroup, a => a.estimatedNumAccounts], ["asc", "desc"])
    .value();
}

function extractSquads(teams: Team[]) {
  return _(teams)
    .map(t => compute.team.teamExtractSquadKeys(t.squads || {}))
    .flatten()
    .uniq()
    .value() as ("a" | "b" | "c")[];
}

//Returns all combinations (order does not matter) of an array of arrays
function allCombinations<T>(arrays: T[][], expectedComboLengthParam?: number): T[][] {
  const combos: T[][] = [];

  const expectedComboLength = typeof expectedComboLengthParam === "number" ? expectedComboLengthParam : arrays.length;

  arrays.forEach((arr, i) => {
    arr.forEach(val => {
      const remainingArrays = arrays.slice(i + 1);

      if (remainingArrays.length + 1 !== expectedComboLength) {
        return;
      }

      if (remainingArrays.length === 0) {
        combos.push([val]);
      } else {
        const restCombos = allCombinations(remainingArrays, expectedComboLength - 1);

        restCombos.forEach(restCombo => {
          const newCombo = [val, ...restCombo];
          combos.push(newCombo);
        });
      }
    });
  });

  return combos;
}

export const prettyCommTagTextMapping: Record<
  GENDERS | "a" | "b" | "c" | CommunicationTag__Constants__TeamRolesEnum | "allUsers",
  { primary: string; secondary?: string }
> = {
  [GENDERS.boys]: {
    primary: "Boys",
    secondary: "Men"
  },
  [GENDERS.girls]: {
    primary: "Girls",
    secondary: "Women"
  },
  [GENDERS.mixed]: {
    primary: "Co-ed"
  },
  a: {
    primary: "Varsity"
  },
  b: {
    primary: "JV",
    secondary: "Junior Varsity"
  },
  c: {
    primary: "Fr/Soph",
    secondary: "Freshman/Sophomore"
  },
  headCoach: {
    primary: "Head Coaches"
  },
  coaches: {
    primary: "All Coaches"
  },
  staff: {
    primary: "All Staff"
  },
  staffAndParents: {
    primary: "Staff & Parents"
  },
  teamAdmins: {
    primary: "Team Admins"
  },
  staffAndPlayers: {
    primary: "Staff & Players"
  },
  allUsers: {
    primary: "All Users"
  }
};

function getTagLookupGroup(tag: BaseTag) {
  const teamOrOrgId = tag.tagType === "teamTag" ? tag.teamId : tag.orgId;
  const birthYear =
    "teamFilters" in tag
      ? tag.teamFilters?.find((f): f is CommunicationTag__Constants__TeamFilter__BirthYear => f.filterType === "birthYear")
          ?.birthYear || ""
      : "";
  const gender =
    "teamFilters" in tag
      ? tag.teamFilters?.find((f): f is CommunicationTag__Constants__TeamFilter__Gender => f.filterType === "gender")?.gender ||
        ""
      : "";

  const squad =
    ("accountFilters" in tag &&
      tag.accountFilters?.find((f): f is CommunicationTag__Constants__AccountFilter__Squad => f.filterType === "squad")?.squad) ||
    "";

  return `${tag.tagType}${teamOrOrgId}${birthYear}${gender}${squad}`;
}

function getTagLookupKey(tag: BaseTag) {
  const role =
    ("accountFilters" in tag &&
      tag.accountFilters?.find((f): f is CommunicationTag__Constants__AccountFilter__TeamRole => f.filterType === "teamRole")
        ?.role) ||
    "all-users";

  return getTagLookupGroup(tag) + role;
}

function getPrettyFilters(filters: CommunicationTag__Constants__GenericFilter[]) {
  if (filters.length) {
    let prettyFilters = filters
      .map(a => {
        if (a.filterType === "squad") {
          return {
            filterType: a.filterType,
            text: prettyCommTagTextMapping[a.squad].primary
          };
        } else if (a.filterType === "teamRole") {
          return {
            filterType: a.filterType,
            text: prettyCommTagTextMapping[a.role].primary
          };
        } else if (a.filterType === "birthYear") {
          return {
            filterType: a.filterType,
            text: a.birthYear
          };
        } else {
          return {
            filterType: a.filterType,
            text: prettyCommTagTextMapping[a.gender].primary
          };
        }
      })
      .sort((a, b) => {
        const ordering = {
          birthYear: 1,
          squad: 2,
          gender: 3,
          teamRole: 4
        };

        return ordering[a.filterType] - ordering[b.filterType];
      })
      .map(a => a.text)
      .join(" ");

    if (!filters.find(a => a.filterType === "teamRole" || a.filterType === "squad")) {
      prettyFilters += ` ${prettyCommTagTextMapping.allUsers.primary}`;
    }

    return prettyFilters;
  } else {
    return prettyCommTagTextMapping.allUsers.primary;
  }
}

// i18n certified - complete
