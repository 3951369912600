import {
  Team,
  TEAM_PERMISSIONS,
  TEAM_ROLES_PERMISSIONS_MAPPING,
  TEAM_ROLES,
  OrgId,
  Org,
  TeamFeatures,
  AccountId
} from "@ollie-sports/models";
import { COLORS } from "../constants/Colors";
import { isOrgAdmin } from "./org.compute";

// NOTE: don't use this method in mobile.
// In mobile use methods in utils/permissions.ts
export function hasPermissionOnTeam(p: { team: Team; accountId: string; permission: TEAM_PERMISSIONS }): boolean {
  const { team, accountId, permission } = p;
  const teamAccount = team.accounts[accountId];

  // Not part of the team
  if (!teamAccount) {
    return false;
  }

  // Have additional permission
  // @ts-ignore
  if (teamAccount.additionalPermissions?.[permission] === true) {
    return true;
  }

  try {
    if (teamAccount.roles) {
      let hasSomePermission = Object.keys(teamAccount.roles)
        // @ts-ignore
        .some(role => TEAM_ROLES_PERMISSIONS_MAPPING[role]?.includes(permission));
      if (hasSomePermission) return true;
    }
  } catch (e) {
    console.error("Trouble checking if a user has a team permission");
  }

  return false;
}

export function isStaffOrOrgAdmin(p: { team: Team; org?: Org; accountId: string }) {
  return isStaff(p) || (p.org ? !!p.org.accounts[p.accountId]?.exists : false);
}

export function isStaff(p: { team: Team; accountId: AccountId }) {
  const teamAccount = p.team.accounts[p.accountId];

  // Not part of the team
  if (!teamAccount) {
    return false;
  }

  return teamAccount.roles.staff;
}

// i18n certified - complete
