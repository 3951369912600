import _ from "lodash";
import {
  AllCollectionNames,
  SoccerStatSnapshot,
  SoccerGameEventBundle,
  SoccerGameEvent,
  SoccerGame,
  PlayerId,
  SoccerGameLeaderboardTypes,
  MvpTypes,
  PATHS_TO_NOT_MIRROR_BY_COLLECTION,
  MIRRORED_COLLECTIONS,
  COLLECTIONS_WITH_DERIVED_ROWS
} from "@ollie-sports/models";
import { common__decompressString } from "../api";
import { isShotEvent } from "../soccer-logic";
import { getServerHelpers } from "../helpers";

export function getPostgresTable(p: { collection: AllCollectionNames; type: "mirror" | "derived" }) {
  if (p.type === "mirror" && MIRRORED_COLLECTIONS.includes(p.collection)) {
    return `mirror_${p.collection.toLowerCase()}`;
  } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerStatSnapshot) {
    return "derived_snapshot_player_shard";
  } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerGameEventBundle) {
    return "derived_soccer_game_event_bundle_key_events";
  } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerGame) {
    return "derived_soccergame_awards_mvp_by_player_shards";
  } else {
    throw new Error(`No table found for specified collection and type! ${p.collection} + ${p.type}`);
  }
}

export function computePostgresFields(p: {
  documents: { doc: object; id: string }[];
  collection: AllCollectionNames;
  type: "derived" | "mirror";
  refreshedAt: Date; //If this function was triggered directly in response to a change (like in a GCP listener), use __updatedAtMS for this
}) {
  let columns: string[];

  //NOTE: Keep these columns in sync with the rows below!
  if (p.type === "mirror" && MIRRORED_COLLECTIONS.includes(p.collection)) {
    columns = ["id", "item", "refreshed_at"];
  } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerStatSnapshot) {
    columns = ["id", "player_id", "calendar_entry_id", "snapshot_id", "player_stats", "timeSpentAtPosition", "refreshed_at"];
  } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerGameEventBundle) {
    columns = ["id", "event", "calendar_entry_id", "soccer_game_event_bundle_id", "refreshed_at"];
  } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerGame) {
    columns = ["id", "soccergame_id", "calendar_entry_id", "player_id", "award_mvp_nodes", "refreshed_at"];
  } else {
    throw new Error(`No table found for specified collection and type! ${p.collection} + ${p.type}`);
  }

  const rows = _(p.documents)
    .flatMap(row => {
      if (p.type === "derived" && p.collection === AllCollectionNames.soccerStatSnapshot) {
        const snapshot = row.doc as SoccerStatSnapshot;
        // For now we only process the final result
        if (snapshot.snapshotType !== "all") {
          return null;
        }

        //NOTE: Keep these rows in sync with the columns above!
        return Object.keys(snapshot.playerStats).map((playerId: string) => [
          playerId + snapshot.id,
          playerId,
          snapshot.calendarEntryId,
          snapshot.id,
          snapshot.playerStats[playerId],
          snapshot.timeSpentAtPositionByPlayerId[playerId],
          p.refreshedAt
        ]);
      } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerGameEventBundle) {
        const soccerGameEventBundle = row.doc as SoccerGameEventBundle;
        const compressedJSONBase64 = (soccerGameEventBundle as any).compressedJSONBase64;
        if (!compressedJSONBase64) {
          return null;
        }
        try {
          const decodedCompressedJSON = JSON.parse(Buffer.from(compressedJSONBase64, "base64").toString());

          const mirrorEvents: SoccerGameEvent[] = [];
          JSON.parse(common__decompressString({ str: decodedCompressedJSON })).forEach((event: SoccerGameEvent) => {
            if (isShotEvent(event)) {
              mirrorEvents.push(event);
            }
          });

          //NOTE: Keep these rows in sync with the columns above!
          return mirrorEvents.map((soccerGameEvent: SoccerGameEvent) => [
            soccerGameEvent.id,
            soccerGameEvent,
            soccerGameEventBundle.calendarEntryId,
            soccerGameEventBundle.id,
            p.refreshedAt
          ]);
        } catch (e) {
          return null;
        }
      } else if (p.type === "derived" && p.collection === AllCollectionNames.soccerGame) {
        const soccerGame = row.doc as SoccerGame;

        if (soccerGame.gameStage !== "ended") {
          return null;
        }

        const shards: Record<
          PlayerId,
          {
            leaderboardWinners?: { [type in SoccerGameLeaderboardTypes]?: 1 };
            mvpWinners?: { [type in MvpTypes]?: 1 };
          }
        > = {};

        if (soccerGame.mvpWinners) {
          Object.keys(soccerGame.mvpWinners).forEach(key => {
            //@ts-ignore
            const playerId = soccerGame.mvpWinners[key];
            if (!shards[playerId]) {
              shards[playerId] = {};
            }
            if (!shards[playerId].mvpWinners) {
              shards[playerId].mvpWinners = {};
            }
            //@ts-ignore
            shards[playerId].mvpWinners[key] = 1;
          });
        }

        if (soccerGame.leaderboardWinners) {
          Object.keys(soccerGame.leaderboardWinners).forEach(key => {
            //@ts-ignore
            const playerId = soccerGame.leaderboardWinners[key];
            if (!shards[playerId]) {
              shards[playerId] = {};
            }
            if (!shards[playerId].leaderboardWinners) {
              shards[playerId].leaderboardWinners = {};
            }
            //@ts-ignore
            shards[playerId].leaderboardWinners[key] = 1;
          });
        }

        if (Object.keys(shards).length > 0) {
          //NOTE: Keep these rows in sync with the columns above!
          return Object.keys(shards).map((playerId: string) => [
            soccerGame.id + playerId,
            soccerGame.id,
            soccerGame.calendarEntryId,
            playerId,
            shards[playerId],
            p.refreshedAt
          ]);
        } else {
          return null;
        }
      } else if (p.type === "mirror") {
        const mirrorDoc = getMirrorDoc(row.doc, p.collection);

        return [[row.id, mirrorDoc, p.refreshedAt]];
      } else {
        throw new Error("Unsupported computed row! " + [p.collection, p.type].toString());
      }
    })
    .compact()
    .value();

  return { table: getPostgresTable(p), columns, rows };
}

export async function resyncDocsToPostgres(p: {
  collection: AllCollectionNames;
  documents: { id: string; doc: object | null }[]; //Doc is null if the document has been deleted!
  pgFormatLib: (query: string, params: (string | number | Date | boolean | object)[]) => string;
  restrictWritesToType?: "mirror" | "derived";
  refreshedAt: Date;
}) {
  const canWriteMirror = !p.restrictWritesToType || p.restrictWritesToType === "mirror";
  const canWriteDerived = !p.restrictWritesToType || p.restrictWritesToType === "derived";

  const {
    table: mirrorTable,
    columns: mirrorCols,
    rows: mirrorInsertRows
  } = (canWriteMirror
    ? computePostgresFields({ ...p, documents: p.documents.filter(a => !!a.doc) as any, type: "mirror" })
    : null) || {};

  const {
    table: derivedTable,
    columns: derivedCols,
    rows: derivedInsertRows
  } = (canWriteDerived && hasDerivedTable(p.collection)
    ? computePostgresFields({ ...p, documents: p.documents.filter(a => !!a.doc) as any, type: "derived" })
    : null) || {};

  const derivedTableInfo = hasDerivedTable(p.collection) ? getDerivedTableInfo({ collection: p.collection }) : null;

  const idsToDelete = p.documents.filter(a => !a.doc).map(a => a.id);

  const { getAppPgPool } = getServerHelpers();

  await Promise.all([
    mirrorInsertRows?.length && mirrorCols && canWriteMirror
      ? getAppPgPool().query(
          p.pgFormatLib(
            `
        INSERT INTO ${mirrorTable} (${mirrorCols.join(",")}) VALUES %L
        ON CONFLICT (id)
        DO UPDATE SET
        ${mirrorCols.map(c => `${c} = EXCLUDED.${c}`).join(",\n")}
        WHERE ${mirrorTable}.refreshed_at < EXCLUDED.refreshed_at
        `,
            mirrorInsertRows
          )
        )
      : null,
    derivedInsertRows?.length && derivedTable && derivedCols && canWriteDerived
      ? getAppPgPool().query(
          p.pgFormatLib(
            `
        INSERT INTO ${derivedTable} (${derivedCols.join(",")}) VALUES %L
        ON CONFLICT (id)
        DO UPDATE SET
        ${derivedCols.map(c => `${c} = EXCLUDED.${c}`).join(",\n")}
        WHERE ${derivedTable}.refreshed_at < EXCLUDED.refreshed_at
        `,
            derivedInsertRows
          )
        )
      : null,
    idsToDelete.length && canWriteMirror
      ? getAppPgPool().query(`DELETE FROM ${mirrorTable} WHERE id = ANY($1::text[])`, [idsToDelete])
      : null,
    idsToDelete.length && derivedTable && derivedTableInfo?.columnReferencingParentId && canWriteDerived
      ? getAppPgPool().query(
          `DELETE FROM ${derivedTable} WHERE ${derivedTableInfo.columnReferencingParentId} = ANY($1::text[])`,
          [idsToDelete]
        )
      : null
  ]);
}

type MirrorSyncState = "good" | "out-of-sync" | "missing" | "extra";
export async function fetchMirrorSyncStateForDocIds(p: {
  collection: AllCollectionNames;
  docIds: string[];
}): Promise<{ id: string; type: MirrorSyncState; postgres: object | null; firestore: object | null }[]> {
  if (!MIRRORED_COLLECTIONS.includes(p.collection)) {
    throw new Error("Can not verify non-mirrored collections!");
  }

  const table = getPostgresTable({ collection: p.collection, type: "mirror" });

  const { getAppPgPool, appOllieFirestoreV2: h } = getServerHelpers();

  const pgRowsProm = getAppPgPool()
    .query(`SELECT * FROM ${table} WHERE id = ANY($1::text[])`, [p.docIds])
    .then(a => a.rows);
  const fsDocs = await Promise.all(p.docIds.map(id => h._RawFirestore.collection(p.collection).doc(id).get()));
  const pgRowsById = _(await pgRowsProm)
    .keyBy(a => a.id)
    .value();

  return fsDocs.map(snap => {
    const fsDoc = getMirrorDoc(snap.data(), p.collection);
    const pgDoc = getMirrorDoc(pgRowsById[snap.id]?.item, p.collection);

    let type: MirrorSyncState;
    if (!fsDoc && pgDoc) {
      type = "extra";
    } else if (!pgDoc && fsDoc) {
      type = "missing";
    } else if (!_.isEqual(pgDoc, fsDoc)) {
      type = "out-of-sync";
    } else {
      type = "good";
    }

    if (fsDoc && fsDoc.__updatedAtMS > Date.now() - 1000 * 60 * 2) {
      //If the firestore document has updated in the past two minutes, ignore b/c postgres very well may not have had time to sync up
      type = "good";
    }

    return { type, id: snap.id, firestore: fsDoc || null, postgres: pgDoc || null };
  });
}

function getDerivedTableInfo(p: { collection: AllCollectionNames }): {
  columnReferencingParentId: string; //Which object on the mirror table all derived rows will equal
} {
  if (p.collection === AllCollectionNames.soccerStatSnapshot) {
    return {
      columnReferencingParentId: "snapshot_id"
    };
  } else if (p.collection === AllCollectionNames.soccerGameEventBundle) {
    return {
      columnReferencingParentId: "soccer_game_event_bundle_id"
    };
  } else if (p.collection === AllCollectionNames.soccerGame) {
    return {
      columnReferencingParentId: "soccergame_id"
    };
  } else {
    throw new Error(`No derived table info found for collection ${p.collection}!`);
  }
}

export function getMirrorDoc(doc: any, collection: AllCollectionNames) {
  if (!doc) {
    return null;
  }

  let mirrorDoc = { ...doc };

  PATHS_TO_NOT_MIRROR_BY_COLLECTION[collection]?.forEach(path => {
    _.unset(mirrorDoc, path);
  });

  if (collection === AllCollectionNames.soccerGameEventBundle) {
    const compressedJSON = (doc as any).compressedJSON;
    if (compressedJSON) {
      const compressedJSONBase64 = Buffer.from(JSON.stringify(compressedJSON)).toString("base64");
      mirrorDoc.compressedJSONBase64 = compressedJSONBase64 ? compressedJSONBase64 : undefined;
    }
  }

  return mirrorDoc as any;
}

function hasDerivedTable(collection: AllCollectionNames) {
  return COLLECTIONS_WITH_DERIVED_ROWS.includes(collection as any);
}
