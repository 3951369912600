import {
  DownPaymentType,
  OrgFeeDetails,
  OrgPaymentPlan,
  OrgPaymentPlanEven,
  OrgPaymentPlanInitialPaymentType,
  OrgPaymentPlanMonthly,
  OrgPaymentPlanPercent,
  OrgPaymentPlanType,
  OrgSeason,
  PaymentMethodType,
  PaymentPlanSinglePaymentInfo
} from "@ollie-sports/models";
import moment, { Moment } from "moment";
import { orgPaymentPlan, orgSeason } from ".";
import { calculateFeesForPayment, roundNumberToTwoDecimalPlaces } from "../utils";
import _ from "lodash";

export function transformChargeDatesToCurrentYearMoments(
  p: ({ type: "manual"; dueDateMS: number } | { type: "registration"; orgSeason: OrgSeason }) & {
    dates: OrgPaymentPlanEven["chargeDatesWithoutYear"] | OrgPaymentPlanPercent["chargeDatesWithoutYear"];
    previewOverridePaymentDateMS?: number;
  }
) {
  const year = p.type === "registration" ? moment(p.orgSeason.startDateMS).year() : moment(p.dueDateMS).year();
  let curr = moment(`${year}-${p.dates[0].date}`, "YYYY-MM-DD");

  const paymentDateMoment = moment(p.previewOverridePaymentDateMS) ?? moment();
  if (curr.isBefore(p.type === "registration" ? moment(p.orgSeason.startDateMS, "YYYY-MM-DD") : paymentDateMoment)) {
    curr.add(1, "year");
  }

  const newDates: Moment[] = [curr];
  for (let i = 1; i < p.dates.length; i++) {
    const next = moment(p.dates[i].date, "MM-DD");
    next.year(curr.year());
    if (next.isSameOrBefore(curr)) {
      next.add(1, "year");
    }
    newDates.push(next);
    curr = next;
  }

  return newDates;
}

export function getDownPaymentAmountCents(p: {
  initialPaymentSettings?: OrgPaymentPlan["initialPaymentSettings"];
  totalAmountCents: number;
}) {
  if (p.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.fixedAmount) {
    return p.initialPaymentSettings.ammountCents;
  } else if (p.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.percent) {
    return p.initialPaymentSettings.percent * p.totalAmountCents;
  }
  return 0;
}

export function getAllPaymentDetailsForOrgPaymentPlan(
  p: ({ type: "manual" } | { type: "registration"; orgSeason: OrgSeason }) & {
    orgPaymentPlan?: OrgPaymentPlan;
    invoiceAmountCents: number;
    invoiceDueDateMS: number;
    numPaymentsOverrideForFlexibleDateOrgPaymentPlan?: number;
    feeDetails?: OrgFeeDetails;
    previewOverridePaymentDateMS?: number;
    extraAmountUserWantsToPayCents: number;
    paymentMethodType?: PaymentMethodType;
  }
): {
  paymentInfo: PaymentPlanSinglePaymentInfo[];
  downPaymentAmountCents: number;
  paymentPlanSetupFeeAmountCents: number;
  extraAmountUserWantsToPayCents: number;
} {
  const paymentDateMoment = moment(p.previewOverridePaymentDateMS) ?? moment();
  if (!p.orgPaymentPlan) {
    return {
      paymentInfo: [
        {
          amountCents: p.invoiceAmountCents,
          originalPaymentDateMS: paymentDateMoment.startOf("day").valueOf(),
          feeAmountCents: calculateFeesForPayment({
            chargeAmountCents: p.invoiceAmountCents,
            feeDetails: p.feeDetails,
            paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
          }), // TODO
          dueDateMS: p.invoiceDueDateMS
        }
      ],
      downPaymentAmountCents: 0,
      paymentPlanSetupFeeAmountCents: 0,
      extraAmountUserWantsToPayCents: 0
    };
  }

  const downPaymentAmountCents = getDownPaymentAmountCents({
    initialPaymentSettings: p.orgPaymentPlan.initialPaymentSettings,
    totalAmountCents: p.invoiceAmountCents
  });
  const totalAfterDownPaymentAndExtraAmount = p.invoiceAmountCents - downPaymentAmountCents - p.extraAmountUserWantsToPayCents;
  if (totalAfterDownPaymentAndExtraAmount === 0) {
    return {
      downPaymentAmountCents: downPaymentAmountCents,
      extraAmountUserWantsToPayCents: p.extraAmountUserWantsToPayCents,
      paymentInfo: [],
      paymentPlanSetupFeeAmountCents: 0
    };
  }

  if (p.orgPaymentPlan.type === OrgPaymentPlanType.customPercent) {
    const chargeDates = transformChargeDatesToCurrentYearMoments(
      p.type === "manual"
        ? {
            type: "manual",
            dates: p.orgPaymentPlan.chargeDatesWithoutYear || [],
            previewOverridePaymentDateMS: p.previewOverridePaymentDateMS,
            dueDateMS: p.invoiceDueDateMS
          }
        : {
            type: "registration",
            orgSeason: p.orgSeason,
            dates: p.orgPaymentPlan.chargeDatesWithoutYear || [],
            previewOverridePaymentDateMS: p.previewOverridePaymentDateMS
          }
    ).map((a, i) => {
      const plan = (p.orgPaymentPlan as OrgPaymentPlanPercent).chargeDatesWithoutYear![i];
      return {
        dateMS: a.valueOf(),
        percent: plan.percent
      };
    });

    let paymentInfo = chargeDates.map(chargeDateInfo => {
      const amountCents =
        Math.floor(totalAfterDownPaymentAndExtraAmount * chargeDateInfo.percent) +
        (p.orgPaymentPlan?.installmentFeeAmountCents ?? 0);
      return {
        amountCents,
        feeAmountCents: calculateFeesForPayment({
          chargeAmountCents: amountCents,
          feeDetails: p.feeDetails,
          paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
        }), // TODO
        originalPaymentDateMS: chargeDateInfo.dateMS,
        dueDateMS:
          chargeDateInfo.dateMS === paymentDateMoment.startOf("day").add(10, "hours").valueOf()
            ? paymentDateMoment.startOf("day").add(10, "hours").valueOf()
            : moment(chargeDateInfo.dateMS).add(p.orgPaymentPlan!.numberOfDaysUntilLate, "days").endOf("day").valueOf()
      };
    });
    const totalAmountAssigned = _.sum(paymentInfo.map(a => a.amountCents));
    let centsRemainingToApply = totalAfterDownPaymentAndExtraAmount - totalAmountAssigned;
    let currentIndex = 0;
    while (centsRemainingToApply > 0) {
      const newAmountCents = paymentInfo[currentIndex].amountCents + 1;
      paymentInfo[currentIndex].amountCents = newAmountCents;
      paymentInfo[currentIndex].feeAmountCents = calculateFeesForPayment({
        chargeAmountCents: newAmountCents,
        feeDetails: p.feeDetails,
        paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
      });
      centsRemainingToApply = centsRemainingToApply - 1;
      currentIndex = currentIndex === paymentInfo.length - 1 ? 0 : currentIndex + 1;
    }

    return {
      downPaymentAmountCents,
      paymentInfo: paymentInfo.filter(a => a.amountCents > 0),
      paymentPlanSetupFeeAmountCents: p.orgPaymentPlan.setupFeeAmountCents ?? 0,
      extraAmountUserWantsToPayCents: p.extraAmountUserWantsToPayCents
    };
  } else if (p.orgPaymentPlan.type === OrgPaymentPlanType.evenlySplit) {
    const chargeDatesInfo = transformChargeDatesToCurrentYearMoments(
      p.type === "manual"
        ? {
            type: "manual",
            dates: p.orgPaymentPlan.chargeDatesWithoutYear || [],
            previewOverridePaymentDateMS: p.previewOverridePaymentDateMS,
            dueDateMS: p.invoiceDueDateMS
          }
        : {
            type: "registration",
            orgSeason: p.orgSeason,
            dates: p.orgPaymentPlan.chargeDatesWithoutYear || [],
            previewOverridePaymentDateMS: p.previewOverridePaymentDateMS
          }
    ).map(a => a.valueOf());

    const paymentInfo = chargeDatesInfo.map(chargeDateInfo => {
      const amountCents =
        Math.floor(totalAfterDownPaymentAndExtraAmount / chargeDatesInfo.length) +
        (p.orgPaymentPlan?.installmentFeeAmountCents ?? 0);
      return {
        amountCents: amountCents,
        feeAmountCents: calculateFeesForPayment({
          chargeAmountCents: amountCents,
          feeDetails: p.feeDetails,
          paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
        }),
        originalPaymentDateMS: chargeDateInfo,
        dueDateMS:
          chargeDateInfo === paymentDateMoment.startOf("day").add(10, "hours").valueOf()
            ? paymentDateMoment.startOf("day").add(10, "hours").valueOf()
            : moment(chargeDateInfo).add(p.orgPaymentPlan!.numberOfDaysUntilLate, "days").endOf("day").valueOf()
      };
    });

    const totalAmountAssigned = _.sum(paymentInfo.map(a => a.amountCents));
    let centsRemainingToApply = totalAfterDownPaymentAndExtraAmount - totalAmountAssigned;
    let currentIndex = 0;
    while (centsRemainingToApply > 0) {
      const newAmountCents = paymentInfo[currentIndex].amountCents + 1;
      paymentInfo[currentIndex].amountCents = newAmountCents;
      paymentInfo[currentIndex].feeAmountCents = calculateFeesForPayment({
        chargeAmountCents: newAmountCents,
        feeDetails: p.feeDetails,
        paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
      });
      centsRemainingToApply = centsRemainingToApply - 1;
      currentIndex = currentIndex === paymentInfo.length - 1 ? 0 : currentIndex + 1;
    }

    return {
      downPaymentAmountCents,
      paymentInfo: paymentInfo.filter(a => a.amountCents > 0),
      paymentPlanSetupFeeAmountCents: p.orgPaymentPlan.setupFeeAmountCents ?? 0,
      extraAmountUserWantsToPayCents: p.extraAmountUserWantsToPayCents
    };
  } else {
    const numPayments = p.numPaymentsOverrideForFlexibleDateOrgPaymentPlan ?? p.orgPaymentPlan.numberPeriods;
    if (numPayments === 0) {
      // Should never happen but avoid dividing by 0
      return {
        downPaymentAmountCents,
        paymentInfo: [],
        paymentPlanSetupFeeAmountCents: p.orgPaymentPlan.setupFeeAmountCents ?? 0,
        extraAmountUserWantsToPayCents: p.extraAmountUserWantsToPayCents
      };
    }
    const paymentDatesMS = getDatesForMonthlyPaymentPlan(
      p.type === "manual"
        ? { plan: p.orgPaymentPlan, numPayments, type: "manual", previewOverridePaymentDateMS: p.previewOverridePaymentDateMS }
        : {
            plan: p.orgPaymentPlan,
            numPayments,
            type: "registration",
            orgSeason: p.orgSeason,
            previewOverridePaymentDateMS: p.previewOverridePaymentDateMS
          }
    );

    const paymentInfo = paymentDatesMS.map(dateMS => {
      const amountCents =
        Math.floor(totalAfterDownPaymentAndExtraAmount / numPayments) + (p.orgPaymentPlan?.installmentFeeAmountCents ?? 0);
      const dataForPayment = {
        amountCents,
        feeAmountCents: calculateFeesForPayment({
          chargeAmountCents: amountCents,
          feeDetails: p.feeDetails,
          paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
        }), // TODO
        originalPaymentDateMS: dateMS,
        dueDateMS:
          dateMS === paymentDateMoment.startOf("day").valueOf()
            ? paymentDateMoment.startOf("day").valueOf()
            : moment(dateMS).add(p.orgPaymentPlan!.numberOfDaysUntilLate, "days").endOf("day").valueOf()
      };
      return dataForPayment;
    });

    const totalAmountAssigned = _.sum(paymentInfo.map(a => a.amountCents));
    let centsRemainingToApply = totalAfterDownPaymentAndExtraAmount - totalAmountAssigned;
    let currentIndex = 0;
    while (centsRemainingToApply > 0) {
      const newAmountCents = paymentInfo[currentIndex].amountCents + 1;
      paymentInfo[currentIndex].amountCents = newAmountCents;
      paymentInfo[currentIndex].feeAmountCents = calculateFeesForPayment({
        chargeAmountCents: newAmountCents,
        feeDetails: p.feeDetails,
        paymentMethodType: p.paymentMethodType ?? PaymentMethodType.card
      });
      centsRemainingToApply = centsRemainingToApply - 1;
      currentIndex = currentIndex === paymentInfo.length - 1 ? 0 : currentIndex + 1;
    }
    return {
      downPaymentAmountCents,
      paymentInfo: paymentInfo.filter(a => a.amountCents > 0),
      paymentPlanSetupFeeAmountCents: p.orgPaymentPlan.setupFeeAmountCents ?? 0,
      extraAmountUserWantsToPayCents: p.extraAmountUserWantsToPayCents
    };
  }
}

function getDatesForMonthlyPaymentPlan(
  p: ({ type: "manual" } | { type: "registration"; orgSeason: OrgSeason }) & {
    plan: OrgPaymentPlanMonthly;
    numPayments: number;
    previewOverridePaymentDateMS?: number;
  }
) {
  const basisMoment = moment(p.previewOverridePaymentDateMS) ?? moment();
  let currentDay = basisMoment.get("D");
  const dayOfMonthToUse = p.plan.dayOfTheMonth ?? currentDay;

  let dates: number[] = [];

  // Need this to handle if it's the 20th and the day to use is the 15th, need move to next month,
  // but if today is the 15th and day to use is 20, then we can use this month
  let month = basisMoment.get("month");
  let currentYear = basisMoment.get("year");

  let numFuturePayments = p.numPayments;
  let momentToAdd: moment.Moment | null = null;
  if (p.plan.initialPaymentSettings?.type === OrgPaymentPlanInitialPaymentType.firstInstallment) {
    // First payment is today or start of season
    momentToAdd = getMomentToAddFromDayMonthYearAccountingForEndOfMonth(currentDay, month, currentYear);
    dates.push(momentToAdd.valueOf());
    numFuturePayments = numFuturePayments - 1;
  }
  // First scheduled installment is always the month after signup
  let currentMonth = month + 1;

  for (let i = 0; i < numFuturePayments; i++) {
    // If we went past December, reset to January and up the year
    if (currentMonth === 12) {
      currentMonth = 0;
      ++currentYear;
    }

    // If they said the 31st, make sure just to use the last day of the month
    momentToAdd = getMomentToAddFromDayMonthYearAccountingForEndOfMonth(dayOfMonthToUse, currentMonth, currentYear);

    dates.push(momentToAdd.valueOf());
    ++currentMonth;
  }
  return dates;
}

function getMomentToAddFromDayMonthYearAccountingForEndOfMonth(dayOfMonthToUse: number, month: number, year: number) {
  let dayOfThisMonthToUse = Math.min(
    dayOfMonthToUse,
    moment(`${year}-${(month + 1).toString().padStart(2, "0")}`, "YYYY-MM").daysInMonth()
  );
  return moment(
    `${year}-${(month + 1).toString().padStart(2, "0")}-${dayOfThisMonthToUse.toString().padStart(2, "0")}`,
    "YYYY-MM-DD"
  )
    .startOf("D")
    .add(10, "hours");
}
