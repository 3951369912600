import { Coupon, CouponType, OrgCoupon, OrgCouponType } from "@ollie-sports/models";
import { PricingInfo, StripePricingInfo } from "../constants/DerivedModels";

export function formatMoneyValue(value: number) {
  const totalDollars = Math.floor(value / 100);
  const thousandDollars = Math.floor(totalDollars / 1000);
  const dollars = totalDollars % 1000;
  return `${thousandDollars ? `${thousandDollars},` : ""}${dollars}.${value % 100 < 10 ? `0${value % 100}` : value % 100}`;
}

export function getDiscountedAmount(p: { appliedCoupon?: OrgCoupon; pricingInfo: StripePricingInfo }) {
  if (!p.appliedCoupon) {
    return 0;
  }
  switch (p.appliedCoupon.type) {
    case OrgCouponType.amountOff:
      return Math.min(p.pricingInfo.numberCents, p.appliedCoupon.amountCents);
    case OrgCouponType.percentOff:
      if (p.appliedCoupon.percent > 0 && p.appliedCoupon.percent <= 1) {
        return p.appliedCoupon.percent * p.pricingInfo.numberCents;
      } else {
        return 0;
      }
  }
}

export function calculateFees(p: { appliedCoupon?: OrgCoupon; pricingInfo: StripePricingInfo }) {
  if (!p.pricingInfo.chargeStripeFees) {
    return 0;
  }
  const costAfterDiscount = getCostAfterDiscount(p);
  return CalculatePaymentFees({ chargeAmount: costAfterDiscount, applicationFeeAmount: p.pricingInfo.applicationFeeAmount });
}

export function getCostAfterDiscount(p: { appliedCoupon?: OrgCoupon; pricingInfo: StripePricingInfo }) {
  return p.pricingInfo.numberCents - getDiscountedAmount(p);
}

export function getTotalAmountToCharge(p: { appliedCoupon?: OrgCoupon; pricingInfo: StripePricingInfo }) {
  return getCostAfterDiscount(p) + calculateFees(p);
}

export function CalculatePaymentFees(p: { chargeAmount: number; applicationFeeAmount?: number }) {
  if (p.chargeAmount === 0) {
    return 0;
  }
  const totalToCharge = (p.chargeAmount + (p.applicationFeeAmount ?? 0) + 30) / (1 - 0.029);
  return Math.round(totalToCharge - p.chargeAmount);
}
