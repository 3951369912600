import { PrettyPlayer, AccountId } from "@ollie-sports/models";
import { extractGuardianAccountIdsFromPlayerBundle, extractSelfAccountIdFromPlayerBundle } from "./playerBundle.compute";
import _ from "lodash";

export function extractGuardianAccountIdsFromPrettyPlayer(p: { prettyPlayer: PrettyPlayer }): string[] {
  if (p.prettyPlayer.derived.accountInfoSource === "player") {
    return [];
  }

  if (!p.prettyPlayer.derived.playerBundle.managingAccounts) {
    return [];
  }

  return extractGuardianAccountIdsFromPlayerBundle({ bundle: p.prettyPlayer.derived.playerBundle });
}

export function extractUniqueGuardianAccountIdsFromPrettyPlayers(p: { prettyPlayers: PrettyPlayer[] }): string[] {
  let pendingGuardianAccounts: string[] = [];
  p.prettyPlayers.forEach(prettyPlayer => {
    pendingGuardianAccounts = [...pendingGuardianAccounts, ...extractGuardianAccountIdsFromPrettyPlayer({ prettyPlayer })];
  });

  return _.uniq(pendingGuardianAccounts);
}

export function extractSelfAccountIdFromPrettyPlayer(p: { prettyPlayer: PrettyPlayer }): string | undefined {
  if (p.prettyPlayer.derived.accountInfoSource === "player") {
    return;
  }

  if (!p.prettyPlayer.derived.playerBundle.managingAccounts) {
    return;
  }

  return extractSelfAccountIdFromPlayerBundle({ bundle: p.prettyPlayer.derived.playerBundle });
}

export function extractChildrenPrettyPlayersForAccountId(p: {
  accountId: AccountId;
  prettyPlayers: PrettyPlayer[];
}): PrettyPlayer[] {
  const result: PrettyPlayer[] = [];
  p.prettyPlayers.forEach(pp => {
    if (pp.derived.accountInfoSource === "playerBundle" || pp.derived.accountInfoSource === "account") {
      if (pp.derived.playerBundle?.managingAccounts?.[p.accountId]?.type === "guardian") {
        result.push(pp);
      }
    }
  });
  return result;
}

export function getMostPlayedPosition(p: {
  timeSpentAtPosition: {
    defender: number;
    forward: number;
    goalkeeper: number;
    midfielder: number;
  };
}) {
  let position = "defender";
  let time = p.timeSpentAtPosition.defender;
  if (p.timeSpentAtPosition.midfielder > time) {
    position = "midfielder";
    time = p.timeSpentAtPosition.midfielder;
  }
  if (p.timeSpentAtPosition.forward > time) {
    position = "forward";
    time = p.timeSpentAtPosition.forward;
  }
  if (p.timeSpentAtPosition.goalkeeper > time) {
    position = "goalkeeper";
    time = p.timeSpentAtPosition.goalkeeper;
  }
  return position;
}

// i18n certified - complete
