import {
  CommunicationTag,
  CommunicationTag__Constants__AccountFilter,
  CommunicationTag__Constants__TeamRolesEnum,
  CommunicationTag__MinimalBase,
  Org,
  Team,
  Team__StaffTypes
} from "@ollie-sports/models";
import _ from "lodash";
import { assertUnreachable } from "../../internal-utils/typescript-utils";
import { DistributiveOmit } from "../../utils";
import { UserTeamContextInfo } from "./constants";

const rolesEnum = CommunicationTag__Constants__TeamRolesEnum;

type Getters = {
  getTeamById: (teamId: string) => Promise<Team | null>;
  getTeamsByOrgId: (orgId: string) => Promise<Team[]>;
  getOrgById: (orgId: string) => Promise<Org | null>;
};

//Sometimes we already have the entire user context, so this lets us get that info without having to query it yet again
export function getGettersFromUserContext(userContext: UserTeamContextInfo): Getters {
  const { orgAdminTeams, userTeams, adminOrgs } = userContext;
  const allTeams: Team[] = _.uniqBy(userTeams.concat(_.flatten(Object.values(orgAdminTeams).map(a => a.orgTeams))), a => a.id);
  const allTeamsMap = allTeams.reduce((acc, t) => {
    acc[t.id] = t;
    return acc;
  }, {} as Record<string, Team>);
  return {
    getOrgById: async orgId => adminOrgs.find(o => o.id === orgId) ?? null,
    getTeamById: async teamId => allTeamsMap[teamId] ?? null,
    getTeamsByOrgId: async orgId => orgAdminTeams[orgId].orgTeams
  };
}

export async function getAccountIdsMatchingTag(
  p: {
    tag: DistributiveOmit<CommunicationTag, keyof CommunicationTag__MinimalBase>;
    userAccountId: string;
  } & Getters
) {
  const { getOrgById, getTeamById, getTeamsByOrgId } = p;

  let accountIds: string[] = [];
  if (p.tag.tagType === "teamTag") {
    const team = await getTeamById(p.tag.teamId);
    if (team) {
      accountIds = getValidAccountIdsForTeam({ team, filters: p.tag.accountFilters });
    }
  } else if (p.tag.tagType === "orgTag") {
    const orgTeams = await getTeamsByOrgId(p.tag.orgId);
    const tag = p.tag;
    accountIds = _(orgTeams)
      .filter(team => {
        return tag.teamFilters
          ? tag.teamFilters.every(filter => {
              if (filter.filterType === "birthYear") {
                return team.birthYear === filter.birthYear;
              } else {
                return team.gender === filter.gender;
              }
            })
          : true;
      })
      .map(team => getValidAccountIdsForTeam({ team, filters: "accountFilters" in p.tag ? p.tag.accountFilters : [] }))
      .flatten()
      .uniq()
      .valueOf();
  } else {
    const thisAdminOrg = await getOrgById(p.tag.orgId);
    accountIds = Object.keys(thisAdminOrg?.accounts || {});
  }

  return accountIds.filter(a => a !== p.userAccountId); //Don't need to communicate with yourself
}

function getValidAccountIdsForTeam(p: { team: Team; filters?: CommunicationTag__Constants__AccountFilter[] }) {
  let accountIds = Object.keys(p.team.accounts);
  const rolesByTeamAccount = getTeamMetaByAccountId(p.team);
  p.filters?.forEach(filter => {
    if (filter.filterType === "teamRole") {
      accountIds = accountIds.filter(accountId => rolesByTeamAccount[accountId].roles.includes(filter.role));
    } else {
      accountIds = accountIds.filter(accountId => rolesByTeamAccount[accountId].squads.includes(filter.squad));
    }
  });

  return accountIds;
}

function getTeamMetaByAccountId(team: Team) {
  const rolesByTeamAccount = {} as Record<
    string,
    { roles: CommunicationTag__Constants__TeamRolesEnum[]; squads: ("a" | "b" | "c")[] }
  >;

  Object.keys(team.accounts).forEach(accountId => {
    const teamAccount = team.accounts[accountId];
    if (!teamAccount) {
      return;
    }
    rolesByTeamAccount[accountId] = {
      roles: [],
      squads: []
    };
    const data = rolesByTeamAccount[accountId];

    if (teamAccount?.roles.athlete) {
      data.roles.push(rolesEnum.staffAndPlayers);
    }

    if (teamAccount?.roles.guardian) {
      data.roles.push(rolesEnum.staffAndParents);
    }

    if (teamAccount.staffTitle) {
      switch (teamAccount.staffTitle) {
        case Team__StaffTypes.headCoach: {
          data.roles.push(
            rolesEnum.headCoach,
            rolesEnum.coaches,
            rolesEnum.staff,
            rolesEnum.staffAndParents,
            rolesEnum.staffAndPlayers
          );
          break;
        }
        case Team__StaffTypes.assistantCoach: {
          data.roles.push(rolesEnum.coaches, rolesEnum.staff, rolesEnum.staffAndParents, rolesEnum.staffAndPlayers);
          break;
        }
        case Team__StaffTypes.staffMember: {
          data.roles.push(rolesEnum.staff, rolesEnum.staffAndParents, rolesEnum.staffAndPlayers);
          break;
        }
        case Team__StaffTypes.teamAdmin: {
          data.roles.push(rolesEnum.staff, rolesEnum.staffAndParents, rolesEnum.staffAndPlayers, rolesEnum.teamAdmins);
          break;
        }
        default: {
          assertUnreachable(teamAccount.staffTitle);
        }
      }
    }

    if (team.squads) {
      //TODO: NEED TO IMPLEMENT THIS WHEN WE HAVE OLLIE 2.5
    }
  });

  return rolesByTeamAccount;
}

// i18n certified - complete
