import {
  SoccerLiveGameTimelineEvent,
  isOpponentTeamId,
  isGoalEvent,
  isShotEvent,
  ConvenienceSoccerGamePlayer,
  isSoccerCornerKickEvent,
  isSoccerSubEvent,
  isSoccerStartClockEvent,
  isSoccerClockEvent,
  isSoccerResumeEvent,
  isPauseEvent,
  SoccerEventMemoryDB,
  computeClockTimePrettyStringForLGM,
  TimelineData,
  TimelineGroup,
  LiveGameModeEventType,
  TimelineDisplayInfo,
  LiveGameModeEventTheme,
  isRelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameMode,
  isRelevantDefensiveEventToToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent,
  SoccerLiveGameTimelineEventType,
  isSoccerFreeKickEvent
} from "../soccer-logic";
import {
  SoccerGameEventType,
  SoccerPositionNumber,
  SUB_OUT_MAGIC_CONSTANT,
  PlayerId,
  SGE_shot,
  SGE_shotWithGoal,
  SoccerGame,
  LiveGameCustomEvent,
  LiveGameReaction,
  LiveGame__Reaction__SGE,
  LiveGame__Reaction__Custom,
  AssetData,
  ShotResultType,
  SoccerStatModes
} from "@ollie-sports/models";

import { findPlayerWithFallback, computeLastSoccerEventDescription } from "../soccer-logic/SoccerEventDescription";
import { combineArrayWithCommasAndAnd, ObjectKeys } from "../utils";
import _ from "lodash";
import { translate } from "@ollie-sports/i18n";

export function combineAndSortGameEventsAndLiveGameData(p: {
  soccerLiveGameTimelineEvents: SoccerLiveGameTimelineEvent[];
  liveGameCustomEvents: LiveGameCustomEvent[];
  liveGameReactions: LiveGameReaction[];
}): TimelineData {
  const groups: TimelineData = [];

  let involvedPlayerIds: PlayerId[] = [];

  // Add game event groups
  // We travers backwards so that we can check the skip the next shot event after a shot result
  const numberLiveGameTimelineEvents = p.soccerLiveGameTimelineEvents.length;
  p.soccerLiveGameTimelineEvents.forEach((event, index) => {
    const gameEventReactions: LiveGame__Reaction__SGE[] = (
      p.liveGameReactions.filter(
        reaction => reaction.eventType === "sge" && reaction.soccerGameEventId === event.id
      ) as LiveGame__Reaction__SGE[]
    ).sort((a, b) => a.createdAtMS - b.createdAtMS);
    let skip = false;
    // Skip formation changes and only show the substitition if players were actually subbed in or out
    if (event.type === SoccerGameEventType.substitute) {
      if (
        !ObjectKeys(event.playerIdToNewPosition).find(
          s =>
            event.playerIdToNewPosition[s] === SUB_OUT_MAGIC_CONSTANT ||
            (event.playerIdToOldPosition && event.playerIdToOldPosition[s] === SUB_OUT_MAGIC_CONSTANT)
        )
      ) {
        skip = true;
      }
    }

    // Skip PostGoalKickoff events because they are only included in LGM to help when deleting goals
    if (event.type === SoccerGameEventType.postGoalKickoff) {
      skip = true;
    }

    involvedPlayerIds = [];
    if ("playerId" in event) {
      involvedPlayerIds.push(event.playerId);
    }
    if (event.type === SoccerGameEventType.shotWithGoal && event.assistingPlayerId) {
      involvedPlayerIds.push(event.assistingPlayerId);
    }
    const group: TimelineGroup = {
      type: "soccerGameEvent",
      allSoccerGameEvents: p.soccerLiveGameTimelineEvents,
      clockTimeMS: event.gameClockTimeMS,
      gamePlayingTimeMS: event.playingTimeMS,
      thisEvent: event,
      thisEventIndex: index,
      reactions: gameEventReactions,
      skip,
      involvedPlayerIds,
      createdAtMS: event.createdAtMS
    };
    groups.push(group);
  });
  // Add timeline entry groups
  p.liveGameCustomEvents.forEach(e => {
    const customEventReactions: LiveGame__Reaction__Custom[] = (
      p.liveGameReactions.filter(
        reaction => reaction.eventType === "custom" && reaction.customSoccerGameEventId === e.id
      ) as LiveGame__Reaction__Custom[]
    ).sort((a, b) => a.createdAtMS - b.createdAtMS);
    const group: TimelineGroup = {
      type: "customEvent",
      clockTimeMS: e.clockTimeMS,
      gamePlayingTimeMS: e.gamePlayingTimeMS,
      reactions: customEventReactions,
      customEvent: e as LiveGameCustomEvent,
      createdAtMS: e.createdAtMS
    };
    groups.push(group);
  });

  // Sort by game playing time but for reactions or custom events that
  // can happen after the game ends, use the createdAtMS
  return groups.sort((a, b) => {
    // DON'T CHANGE! Have to explicitly check for not undefined because sometimes it is 0
    // and we are okay with that, so using !! doesnt work in this case.
    if (a.gamePlayingTimeMS !== undefined && b.gamePlayingTimeMS !== undefined) {
      return a.gamePlayingTimeMS - b.gamePlayingTimeMS;
    }
    return a.createdAtMS - b.createdAtMS;
  });
}

export function getTimelineDisplayInfoForTimelineGroup(p: {
  timelineGroup: TimelineGroup;
  conveniencePlayers: ConvenienceSoccerGamePlayer[];
  game?: SoccerGame;
  memoryDB?: SoccerEventMemoryDB;
  isGated?: boolean;
  locale: string;
}): TimelineDisplayInfo {
  let isMyTeam: boolean = true;
  let minuteString =
    p.game && p.memoryDB && p.timelineGroup.type !== "gatedEvent"
      ? computeClockTimePrettyStringForLGM({
          createdAtMS: p.timelineGroup.createdAtMS,
          allClockEvents: p.memoryDB.presets.clockEvents(),
          game: p.game
        })
      : "";

  let title: string = "";
  let subtitle: string = "";
  let type: LiveGameModeEventType = "custom";
  let theme: LiveGameModeEventTheme = "white";
  let shouldHide: boolean = false;
  if (p.timelineGroup.type === "soccerGameEvent") {
    if ("playerTeamId" in p.timelineGroup.thisEvent) {
      isMyTeam = !isOpponentTeamId(p.timelineGroup.thisEvent.playerTeamId);
    }
    if (isGoalEvent(p.timelineGroup.thisEvent)) {
      theme = "dark";
      type = "goal";
    } else if (isShotEvent(p.timelineGroup.thisEvent)) {
      theme = "light";
      if (
        p.timelineGroup.thisEvent.shotResultType === ShotResultType.goalieShotBlock ||
        p.timelineGroup.thisEvent.shotResultType === ShotResultType.goalieShotCatch
      ) {
        if (isMyTeam) {
          type = "shotOnGoal";
        } else {
          type = "save";
        }
      } else {
        type = "shot";
      }
    } else if (p.timelineGroup.thisEvent.type === SoccerGameEventType.yellowCardInfraction) {
      type = "yellowCard";
    } else if (p.timelineGroup.thisEvent.type === SoccerGameEventType.redCardInfraction) {
      type = "redCard";
    } else if (isSoccerCornerKickEvent(p.timelineGroup.thisEvent)) {
      type = "cornerKick";
    } else if (isSoccerSubEvent(p.timelineGroup.thisEvent)) {
      type = "substitition";
    } else if (p.timelineGroup.thisEvent.type === SoccerGameEventType.offsideInfraction) {
      type = "offside";
    } else if (
      p.timelineGroup.thisEvent.type === SoccerGameEventType.foulInfraction ||
      p.timelineGroup.thisEvent.type === SoccerGameEventType.handballInfraction
    ) {
      type = "infraction";
    } else if (isSoccerClockEvent(p.timelineGroup.thisEvent)) {
      type = "clock";
      if (isSoccerResumeEvent(p.timelineGroup.thisEvent) || isPauseEvent(p.timelineGroup.thisEvent)) {
        const reason =
          "reason" in p.timelineGroup.thisEvent ? p.timelineGroup.thisEvent.reason : p.timelineGroup.thisEvent.pauseReason;
        if (reason !== "manual-main-official-stoppage" && reason !== "game-delay") {
          shouldHide = true;
        }
      }
    } else if (isRelevantDefensiveEventToToShowUpOnLiveGameModeFollowingRelevantOffensiveEvent(p.timelineGroup.thisEvent)) {
      type = "recoveryFromCross";
    } else if (isRelevantOffensiveEventToWarrantDefensiveEventToShowUpOnLiveGameMode(p.timelineGroup.thisEvent)) {
      type = "cross";
    } else if (isSoccerResumeEvent(p.timelineGroup.thisEvent) || isPauseEvent(p.timelineGroup.thisEvent)) {
      type = "pauseOrResumeEventThatDoesNotStopOrStartClock";
      const reason =
        "reason" in p.timelineGroup.thisEvent ? p.timelineGroup.thisEvent.reason : p.timelineGroup.thisEvent.pauseReason;
      if (reason !== "manual-main-official-stoppage" && reason !== "game-delay") {
        shouldHide = true;
      }
    } else if (isSoccerFreeKickEvent(p.timelineGroup.thisEvent)) {
      type = "freeKick";
    } else if (p.timelineGroup.thisEvent.type === SoccerGameEventType.forfeitInfractionPK) {
      type = "pkForfeit";
    }

    title =
      p.conveniencePlayers && p.memoryDB && (p.game?.statMode !== SoccerStatModes.team ? p.conveniencePlayers.length : true) //Prevent flash of description with generic players in non team mode
        ? computeLastSoccerEventDescription({
            completeRoster: p.conveniencePlayers,
            memoryDB: p.memoryDB,
            eventIndex: p.timelineGroup.thisEventIndex,
            verbose: true,
            soccerGame: p.game,
            locale: p.locale
          })
        : "";

    if (isGoalEvent(p.timelineGroup.thisEvent) && isMyTeam && p.isGated) {
      title = translate({ defaultMessage: "Your team scored! Upgrade to see who scored and assisted.", serverLocale: p.locale });
    }

    shouldHide = shouldHide || p.timelineGroup.skip;
  } else if (p.timelineGroup.type === "customEvent") {
    isMyTeam = true;
    type = "custom";
    title = p.timelineGroup.customEvent.text ?? "";
  } else if (p.timelineGroup.type === "systemEvent") {
    if (p.timelineGroup.systemType === "startGame") {
      title = `*${translate({ defaultMessage: "Welcome to Live Game Mode!", serverLocale: p.locale })}* ${translate({
        defaultMessage: "Add photos and videos and they will show up on the team and players' timelines after the game!",
        serverLocale: p.locale
      })}`;
      type = "system";
    } else if (p.timelineGroup.systemType === "photoPlea") {
      title = `*${translate({ defaultMessage: "No photos or videos yet", serverLocale: p.locale })} 😢.* ${translate({
        defaultMessage: "Click here to request photo and video posts from other parents.",
        serverLocale: p.locale
      })}`;
      type = "system";
    }
  } else {
    title = `${translate(
      {
        defaultMessage: "{numEventsString} {numEvents, plural, zero {events} one {event} other {events}} occurred",
        serverLocale: p.locale
      },
      { numEventsString: `*${p.timelineGroup.eventsTypes.length}*`, numEvents: p.timelineGroup.eventsTypes.length }
    )}${p.timelineGroup.eventsTypes.length > 2 ? getIncludedGatedEventsString(p.timelineGroup.eventsTypes, p.locale) : ""}.`;
    type = "gated";
    subtitle = translate({ defaultMessage: "Upgrade to unlock Full Live Game Mode.", serverLocale: p.locale });
  }
  return {
    isMyTeam,
    theme,
    type,
    shouldHide,
    minuteString,
    title,
    subtitle,
    isGated: p.isGated ?? false
  };
}

function getIncludedGatedEventsString(events: SoccerLiveGameTimelineEventType[], locale: string) {
  const interestingEventsCount: PartialRecord<InterestingEventTypes, number> = events.reduce((acc, type) => {
    if (
      type === SoccerGameEventType.shot ||
      type === SoccerGameEventType.shotShootoutPK ||
      type === SoccerGameEventType.freeKickLong ||
      type === SoccerGameEventType.freeKickPass ||
      type === SoccerGameEventType.freeKickCross ||
      type === SoccerGameEventType.yellowCardInfraction ||
      type === SoccerGameEventType.redCardInfraction ||
      type === SoccerGameEventType.cornerKickCross ||
      type === SoccerGameEventType.cornerKickShort ||
      type === SoccerGameEventType.cross
    ) {
      let category: InterestingEventTypes;
      switch (type) {
        case SoccerGameEventType.freeKickCross:
        case SoccerGameEventType.freeKickLong:
        case SoccerGameEventType.freeKickPass:
          category = "freeKicks";
          break;
        case SoccerGameEventType.shot:
        case SoccerGameEventType.shotShootoutPK:
          category = "shots";
          break;
        case SoccerGameEventType.yellowCardInfraction:
          category = "yellowCards";
          break;
        case SoccerGameEventType.redCardInfraction:
          category = "redCards";
          break;
        case SoccerGameEventType.cross:
          category = "crosses";
          break;
        case SoccerGameEventType.cornerKickCross:
        case SoccerGameEventType.cornerKickShort:
          category = "cornerKicks";
          break;
      }
      const currentCount = acc[category];
      acc[category] = currentCount ? currentCount + 1 : 1;
    }
    return acc;
  }, {} as PartialRecord<InterestingEventTypes, number>);

  let sortedEvents: { type: InterestingEventTypes; count: number }[] = [];

  ObjectKeys(interestingEventsCount).map(type => {
    sortedEvents.push({
      type,
      count: interestingEventsCount[type] ?? 0
    });
  });

  sortedEvents = sortedEvents
    .filter(e => e.count)
    .sort((a, b) => b.count - a.count)
    .slice(0, 3);
  const prettyString = combineArrayWithCommasAndAnd(
    sortedEvents.map(e => {
      switch (e.type) {
        case "cornerKicks":
          return e.count === 1
            ? translate({ defaultMessage: "a corner kick", serverLocale: locale })
            : translate.common(locale).CornerKicks.toLowerCase();
        case "crosses":
          return e.count === 1
            ? translate({ defaultMessage: "a cross", serverLocale: locale })
            : translate.common(locale).Crosses.toLowerCase();
        case "freeKicks":
          return e.count === 1
            ? translate({ defaultMessage: "a free kick", serverLocale: locale })
            : translate.common(locale).FreeKicks.toLowerCase();
        case "shots":
          return e.count === 1
            ? translate({ defaultMessage: "a shot", serverLocale: locale })
            : translate.common(locale).Shots.toLowerCase();
        case "yellowCards":
          return e.count === 1
            ? translate({ defaultMessage: "a yellow card", serverLocale: locale })
            : translate.common(locale).YellowCards.toLowerCase();
        case "redCards":
          return e.count === 1
            ? translate({ defaultMessage: "a red card", serverLocale: locale })
            : translate.common(locale).RedCards.toLowerCase();
      }
    }),
    locale
  );

  return ` ${translate({ defaultMessage: "including {prettyString}", serverLocale: locale }, { prettyString })}`;
}

type InterestingEventTypes = "shots" | "yellowCards" | "redCards" | "freeKicks" | "crosses" | "cornerKicks";

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

// i18n certified - complete
