import {
  AccountId,
  FlexDonation,
  FundraiserBasicData,
  FundraiserPlayerPrizeInfo,
  FundraisingOrgTopPlayer,
  FundraisingOrgTopTeam,
  FundraisingPrize,
  FundraisingTeamTopPlayer,
  PlayerId,
  PrettyPlayer,
  PrizePlayerStatus,
  PrizePlayerStatusDetails,
  PRIZE_TYPES,
  Team,
  TeamId
} from "@ollie-sports/models";
import { player } from ".";
import { formatMoneyCentsToDollarCentPrettyString, ObjectKeys } from "../utils";
import _, { isDate } from "lodash";
import { translate } from "@ollie-sports/i18n";
import { ReferralPrize3Referrals, ReferralPrize6Referrals } from "../constants/FundraisingConstants";

export function getPlayerTotalAmountRaised(donations: FlexDonation[]) {
  return donations.reduce((acc, val) => {
    acc += val.amount;
    return acc;
  }, 0);
}

export function getTeamTotalAmountRaised(playerAmounts: { playerId: string; amount: number }[]) {
  return playerAmounts.reduce((acc, val) => {
    acc += val.amount;
    return acc;
  }, 0);
}

export function getTeamAverageAmountRaised(playerAmounts: { playerId: string; amount: number }[]) {
  let numPlayersWhoHaveRaised = 0;
  const total = playerAmounts.reduce((acc, val) => {
    acc += val.amount;
    if (val.amount > 0) {
      numPlayersWhoHaveRaised = numPlayersWhoHaveRaised + 1;
    }
    return acc;
  }, 0);
  return numPlayersWhoHaveRaised ? total / numPlayersWhoHaveRaised : 0;
}

export function getPlayerLeaderBoard(p: {
  playerAmounts: { playerId: string; amount: number; pageViews?: number; flexTeamId: number }[];
  prettyPlayers: PrettyPlayer[];
}) {
  return _.compact(
    p.playerAmounts.map(a => {
      const prettyPlayer = p.prettyPlayers.find(pp => pp.player.id === a.playerId);
      if (!prettyPlayer) {
        return null;
      }
      return {
        name: `${prettyPlayer.derived.accountInfo.firstName} ${prettyPlayer.derived.accountInfo.lastName}`,
        amount: a.amount,
        playerId: prettyPlayer.player.id,
        pageViews: a.pageViews ?? 0,
        flexTeamId: a.flexTeamId
      };
    })
  );
}

export function getOrgTotalAmountRaised(teamAmounts: { teamId: TeamId; amount: number }[]) {
  return teamAmounts.reduce((acc, val) => {
    acc += val.amount;
    return acc;
  }, 0);
}

export function getOrgAverageAmountRaised(teamAmounts: { teamId: TeamId; amount: number }[]) {
  let numTeamsWhoHaveRaised = 0;
  const total = teamAmounts.reduce((acc, val) => {
    acc += val.amount;
    if (val.amount > 0) {
      numTeamsWhoHaveRaised = numTeamsWhoHaveRaised + 1;
    }
    return acc;
  }, 0);
  return numTeamsWhoHaveRaised > 0 ? total / numTeamsWhoHaveRaised : 0;
}

export function getSortedPrizes(prizes: FundraisingPrize[]) {
  const playerAmountPrizes = prizes.filter(prize => prize.type === PRIZE_TYPES.playerAmount);
  const playerRankOnTeamPrizes = prizes.filter(prize => prize.type === PRIZE_TYPES.playerRankOnTeam);
  const playerRankInOrgPrizes = prizes.filter(prize => prize.type === PRIZE_TYPES.playerRankInOrg);
  const playerPageViewPrizes = prizes.filter(prize => prize.type === PRIZE_TYPES.pageViews);
  const teamAmountPrizes = prizes.filter(prize => prize.type === PRIZE_TYPES.teamAmount);
  const teamRankInOrgPrizes = prizes.filter(prize => prize.type === PRIZE_TYPES.teamRankInOrg);

  return [
    ..._.orderBy(playerAmountPrizes, "amount", "asc"),
    ..._.orderBy(playerPageViewPrizes, "amount", "asc"),
    ...playerRankOnTeamPrizes,
    ...playerRankInOrgPrizes,
    ..._.orderBy(teamAmountPrizes, "amount", "asc"),
    ...teamRankInOrgPrizes
  ];
}

export function getFundraisingPlayerPageLink(p: { flexAppUrl: string; flexUUID: string }) {
  return `${p.flexAppUrl}/player/${p.flexUUID}`;
}

export function getFundraisingTeamPageLink(p: { flexAppUrl: string; flexSlug: string }) {
  return `${p.flexAppUrl}/f/${p.flexSlug}`;
}

export function isRafflePrize(prize: FundraisingPrize) {
  return !prize.isCustom && prize.name.match(/raffle/i) && parseInt(prize.name.slice(0, prize.name.indexOf("affle") - 2));
}

function extractNumRaffleTicketsFromPrize(prize: FundraisingPrize) {
  return isRafflePrize(prize) ? parseInt(prize.name.slice(0, prize.name.indexOf("affle") - 2)) : 0;
}
export function calculateTopTeamPrizesInOrg(p: {
  prizes: FundraisingPrize[];
  teams: FundraisingOrgTopTeam[];
}): { teamId: TeamId; prize: FundraisingPrize; isTie: boolean }[] {
  const teamsGettingPrizes: { teamId: TeamId; prize: FundraisingPrize; isTie: boolean }[] = [];
  const prizes = p.prizes.filter(prize => prize.type === PRIZE_TYPES.teamRankInOrg);

  prizes.forEach(prize => {
    const winnersOfThePrize = p.teams.filter(t => t.place === prize.amount && t.amount > 0);
    const isTie = winnersOfThePrize.length > 1;
    winnersOfThePrize.forEach(winner => {
      teamsGettingPrizes.push({
        isTie,
        prize,
        teamId: winner.teamId
      });
    });
  });
  return teamsGettingPrizes;
}

export function calculateNonRaffleTicketPrizesForTopPlayersOnTeamOrOg(p: {
  prizes: FundraisingPrize[];
  players: (FundraisingTeamTopPlayer | FundraisingOrgTopPlayer)[];
  type: "team" | "org";
}): { playerId: PlayerId; prize: FundraisingPrize; isTie: boolean }[] {
  const playersGettingPrizes: { playerId: PlayerId; prize: FundraisingPrize; isTie: boolean }[] = [];
  const prizes =
    p.type === "team"
      ? p.prizes.filter(prize => prize.type === PRIZE_TYPES.playerRankOnTeam && !isRafflePrize(prize))
      : p.prizes.filter(prize => prize.type === PRIZE_TYPES.playerRankInOrg && !isRafflePrize(prize));

  prizes.forEach(prize => {
    const winnersOfThePrize = p.players.filter(pl => pl.place === prize.amount && pl.amount > 0);
    const isTie = winnersOfThePrize.length > 1;
    winnersOfThePrize.forEach(winner => {
      playersGettingPrizes.push({
        isTie,
        prize,
        playerId: winner.playerId
      });
    });
  });
  return playersGettingPrizes;
}

export function calculateRaffleTicketsForTopPlayersOnTeamOrOg(p: {
  prizes: FundraisingPrize[];
  players: (FundraisingTeamTopPlayer | FundraisingOrgTopPlayer)[];
  type: "team" | "org";
}): { playerId: PlayerId; numRaffleTickets: number; prize?: FundraisingPrize; isTie: boolean }[] {
  const playersGettingTickets: { playerId: PlayerId; numRaffleTickets: number; prize?: FundraisingPrize; isTie: boolean }[] = [];
  const rafflePrizes =
    p.type === "team"
      ? p.prizes.filter(prize => prize.type === PRIZE_TYPES.playerRankOnTeam && isRafflePrize(prize))
      : p.prizes.filter(prize => prize.type === PRIZE_TYPES.playerRankInOrg && isRafflePrize(prize));
  let placeToNumTicketsRemaining = rafflePrizes.reduce(
    (acc, val) => {
      if (val.amount === 1 || val.amount === 2 || val.amount === 3) {
        const numRaffleTix = extractNumRaffleTicketsFromPrize(val);
        acc[val.amount] = acc[val.amount] + numRaffleTix;
      }
      return acc;
    },
    {
      1: 0,
      2: 0,
      3: 0
    } as Record<1 | 2 | 3, number>
  );

  const playersInTop3 = p.players.filter(tp => tp.place <= 3 && tp.amount > 0);

  // Calculate num raffle tickets for first place winner and ties
  const playersInFirstPlace = playersInTop3.filter(tp => tp.place === 1);
  const numFirstPlaceWinners = playersInFirstPlace.length;
  const numTicketsToFirstPlaceWinners =
    numFirstPlaceWinners === 1
      ? placeToNumTicketsRemaining[1]
      : numFirstPlaceWinners === 2
      ? placeToNumTicketsRemaining[1] + placeToNumTicketsRemaining[2]
      : numFirstPlaceWinners > 3
      ? placeToNumTicketsRemaining[1] + placeToNumTicketsRemaining[2] + placeToNumTicketsRemaining[3]
      : 0;
  playersInFirstPlace.forEach(pl => {
    playersGettingTickets.push({
      playerId: pl.playerId,
      // In the rare case that the split means less than 1 ticket, at least give them 1
      numRaffleTickets: Math.max(Math.ceil(numTicketsToFirstPlaceWinners / numFirstPlaceWinners), 1),
      prize: rafflePrizes.find(prize => prize.amount === 1),
      isTie: numFirstPlaceWinners > 1
    });
  });

  // Clear out available tickets after awarding first place winner and ties
  placeToNumTicketsRemaining[1] = 0;
  if (numFirstPlaceWinners > 1) {
    placeToNumTicketsRemaining[2] = 0;
  }
  if (numFirstPlaceWinners > 2) {
    placeToNumTicketsRemaining[3] = 0;
  }

  // Calculate num raffle tickets for 2nd place winner and ties
  const playersInSecondPlace = playersInTop3.filter(tp => tp.place === 2);
  const numSecondPlaceWinners = playersInSecondPlace.length;
  const numTicketsToSecondPlaceWinners =
    numSecondPlaceWinners === 1
      ? placeToNumTicketsRemaining[2]
      : numSecondPlaceWinners > 1
      ? placeToNumTicketsRemaining[2] + placeToNumTicketsRemaining[3]
      : 0;
  playersInSecondPlace.forEach(pl => {
    playersGettingTickets.push({
      playerId: pl.playerId,
      // In the rare case that the split means less than 1 ticket, at least give them 1
      numRaffleTickets: Math.max(Math.ceil(numTicketsToSecondPlaceWinners / numSecondPlaceWinners), 1),
      prize: rafflePrizes.find(prize => prize.amount === 2),
      isTie: numSecondPlaceWinners > 1
    });
  });

  // Clear out available tickets after awarding 2nd place winner and ties
  placeToNumTicketsRemaining[2] = 0;
  if (numSecondPlaceWinners > 1) {
    placeToNumTicketsRemaining[3] = 0;
  }

  // Calculate num raffle tickets for 3rd place winner and ties
  const playersInThirdPlace = playersInTop3.filter(tp => tp.place === 3);
  const numThirdPlaceWinners = playersInThirdPlace.length;
  const numTicketsToThirdPlaceWinners = placeToNumTicketsRemaining[3];
  playersInThirdPlace.forEach(pl => {
    playersGettingTickets.push({
      playerId: pl.playerId,
      // In the rare case that the split means less than 1 ticket, at least give them 1
      numRaffleTickets: Math.max(Math.ceil(numTicketsToThirdPlaceWinners / numThirdPlaceWinners), 1),
      prize: rafflePrizes.find(prize => prize.amount === 3),
      isTie: numThirdPlaceWinners > 1
    });
  });

  return playersGettingTickets;
}

export function calculatePlayerPrizeAndRaffleData(p: {
  amountRaised: number;
  numberOfViews: number;
  allPrizes: FundraisingPrize[];
  teamPlayers: FundraisingTeamTopPlayer[];
  playerId: PlayerId;
  teamId: TeamId;
  hasFundraiserEnded: boolean;
  showReferralPrizes: boolean;
  numReferrals: number;
  orgPlayers?: FundraisingOrgTopPlayer[];
  orgTeams?: FundraisingOrgTopTeam[];
}): FundraiserPlayerPrizeInfo {
  let totalRaffleTicketsEarned = 0;
  let totalRaffleTicketsProjected = 0;
  const prizesCompleted: PrizePlayerStatusDetails[] = [];
  const prizesCurrentlyWinning: PrizePlayerStatusDetails[] = [];
  const rafflePrizes = p.allPrizes.filter(prize => isRafflePrize(prize));

  rafflePrizes.forEach(prize => {
    const numRaffleTicketsString = prize.name.slice(0, prize.name.indexOf("affle") - 2);
    if (parseInt(numRaffleTicketsString)) {
      const numRaffleTickets = parseInt(numRaffleTicketsString);
      switch (prize.type) {
        case PRIZE_TYPES.pageViews:
          if (p.numberOfViews >= prize.amount) {
            totalRaffleTicketsEarned += numRaffleTickets;
            prizesCompleted.push({
              type: "raffle",
              prize,
              isTie: false,
              numTickets: numRaffleTickets,
              status: PrizePlayerStatus.won
            });
          }
          break;
        case PRIZE_TYPES.playerAmount:
          if (p.amountRaised >= prize.amount) {
            totalRaffleTicketsEarned += numRaffleTickets;
            prizesCompleted.push({
              type: "raffle",
              prize,
              isTie: false,
              numTickets: numRaffleTickets,
              status: PrizePlayerStatus.won
            });
          }
          break;
        case PRIZE_TYPES.playerRankInOrg:
        case PRIZE_TYPES.playerRankOnTeam:
        case PRIZE_TYPES.teamAmount:
        case PRIZE_TYPES.teamRankInOrg:
          break;
      }
    }
  });

  const topPlayersInOrgRaffleTickets = calculateRaffleTicketsForTopPlayersOnTeamOrOg({
    prizes: p.allPrizes,
    players: p.orgPlayers ?? [],
    type: "org"
  });
  const raffleTicketDataForPlayerInOrg = topPlayersInOrgRaffleTickets.find(pl => pl.playerId === p.playerId);
  if (raffleTicketDataForPlayerInOrg?.numRaffleTickets) {
    if (p.hasFundraiserEnded) {
      totalRaffleTicketsEarned += raffleTicketDataForPlayerInOrg.numRaffleTickets;
      if (raffleTicketDataForPlayerInOrg.prize) {
        prizesCompleted.push({
          type: "raffle",
          prize: raffleTicketDataForPlayerInOrg.prize,
          isTie: raffleTicketDataForPlayerInOrg.isTie,
          numTickets: raffleTicketDataForPlayerInOrg.numRaffleTickets,
          status: p.hasFundraiserEnded ? PrizePlayerStatus.won : PrizePlayerStatus.winning
        });
      }
    } else {
      totalRaffleTicketsProjected += raffleTicketDataForPlayerInOrg.numRaffleTickets;
      if (raffleTicketDataForPlayerInOrg.prize) {
        prizesCurrentlyWinning.push({
          type: "raffle",
          prize: raffleTicketDataForPlayerInOrg.prize,
          isTie: raffleTicketDataForPlayerInOrg.isTie,
          numTickets: raffleTicketDataForPlayerInOrg.numRaffleTickets,
          status: p.hasFundraiserEnded ? PrizePlayerStatus.won : PrizePlayerStatus.winning
        });
      }
    }
  }

  const topPlayersOnTeamRaffleTickets = calculateRaffleTicketsForTopPlayersOnTeamOrOg({
    prizes: p.allPrizes,
    players: p.teamPlayers,
    type: "team"
  });
  const raffleTicketDataOnTeam = topPlayersOnTeamRaffleTickets.find(pl => pl.playerId === p.playerId);
  if (raffleTicketDataOnTeam?.numRaffleTickets) {
    if (p.hasFundraiserEnded) {
      totalRaffleTicketsEarned += raffleTicketDataOnTeam.numRaffleTickets;
      if (raffleTicketDataOnTeam.prize) {
        prizesCompleted.push({
          type: "raffle",
          prize: raffleTicketDataOnTeam.prize,
          isTie: raffleTicketDataOnTeam.isTie,
          numTickets: raffleTicketDataOnTeam.numRaffleTickets,
          status: p.hasFundraiserEnded ? PrizePlayerStatus.won : PrizePlayerStatus.winning
        });
      }
    } else {
      totalRaffleTicketsProjected += raffleTicketDataOnTeam.numRaffleTickets;
      if (raffleTicketDataOnTeam.prize) {
        prizesCurrentlyWinning.push({
          type: "raffle",
          prize: raffleTicketDataOnTeam.prize,
          isTie: raffleTicketDataOnTeam.isTie,
          numTickets: raffleTicketDataOnTeam.numRaffleTickets,
          status: p.hasFundraiserEnded ? PrizePlayerStatus.won : PrizePlayerStatus.winning
        });
      }
    }
  }

  const topPlayersOnTeamNonRafflePrizes = calculateNonRaffleTicketPrizesForTopPlayersOnTeamOrOg({
    type: "team",
    players: p.teamPlayers,
    prizes: p.allPrizes
  });
  const topPlayersOnOrgNonRafflePrizes = p.orgPlayers
    ? calculateNonRaffleTicketPrizesForTopPlayersOnTeamOrOg({
        type: "org",
        players: p.orgPlayers,
        prizes: p.allPrizes
      })
    : [];
  const topTeamInOrgPrizes = p.orgTeams
    ? calculateTopTeamPrizesInOrg({
        prizes: p.allPrizes,
        teams: p.orgTeams
      })
    : [];

  const topTeamAndOrgNonRafflePrizes =
    [...topPlayersOnTeamNonRafflePrizes, ...topPlayersOnOrgNonRafflePrizes, ...topTeamInOrgPrizes].filter(
      a => ("playerId" in a && a.playerId === p.playerId) || ("teamId" in a && a.teamId === p.teamId)
    ) ?? [];

  topTeamAndOrgNonRafflePrizes.forEach(prizeData => {
    if (p.hasFundraiserEnded) {
      prizesCompleted.push({
        isTie: prizeData.isTie,
        prize: prizeData.prize,
        status: PrizePlayerStatus.won,
        type: "nonRaffle"
      });
    } else {
      prizesCurrentlyWinning.push({
        isTie: prizeData.isTie,
        prize: prizeData.prize,
        status: PrizePlayerStatus.won,
        type: "nonRaffle"
      });
    }
  });

  const winningOrWonPrizeIds = [...prizesCompleted, ...prizesCurrentlyWinning].map(pz => pz.prize.prizeId);

  const prizesNotWinning: PrizePlayerStatusDetails[] = _.compact(
    p.allPrizes.map(prize => {
      if (!winningOrWonPrizeIds.includes(prize.prizeId)) {
        if (isRafflePrize(prize)) {
          return {
            type: "raffle",
            isTie: false,
            prize,
            status: PrizePlayerStatus.notWinning,
            numTickets: extractNumRaffleTicketsFromPrize(prize)
          } as PrizePlayerStatusDetails;
        } else {
          return {
            type: "nonRaffle",
            isTie: false,
            prize,
            status: PrizePlayerStatus.notWinning
          } as PrizePlayerStatusDetails;
        }
      }
      return null;
    })
  );

  if (p.showReferralPrizes) {
    if (p.numReferrals >= 3) {
      totalRaffleTicketsEarned += 1;
      prizesCompleted.push({
        isTie: false,
        numTickets: 1,
        prize: ReferralPrize3Referrals,
        status: PrizePlayerStatus.won,
        type: "raffle"
      });
    } else {
      prizesNotWinning.push({
        isTie: false,
        numTickets: 1,
        prize: ReferralPrize3Referrals,
        status: PrizePlayerStatus.won,
        type: "raffle"
      });
    }
    if (p.numReferrals >= 6) {
      totalRaffleTicketsEarned += 2;
      prizesCompleted.push({
        isTie: false,
        numTickets: 2,
        prize: ReferralPrize6Referrals,
        status: PrizePlayerStatus.won,
        type: "raffle"
      });
    } else {
      prizesNotWinning.push({
        isTie: false,
        numTickets: 2,
        prize: ReferralPrize6Referrals,
        status: PrizePlayerStatus.won,
        type: "raffle"
      });
    }
  }

  return {
    totalRaffleTicketsEarned,
    totalRaffleTicketsProjected,
    prizesCompleted,
    prizesCurrentlyWinning,
    prizesNotWinning
  };
}

export function getPrettyStringForPrizeTypeDetail(p: { locale: string; prize: FundraisingPrize }) {
  switch (p.prize.type) {
    case PRIZE_TYPES.pageViews:
      return translate(
        { defaultMessage: `Get {amount} {amount, plural, one {page view} other {page views}}`, serverLocale: p.locale },
        { amount: p.prize.amount }
      );
    case PRIZE_TYPES.playerAmount:
      return `${translate(
        { defaultMessage: `Raise {amount}`, serverLocale: p.locale, description: "amount raised" },
        { amount: formatMoneyCentsToDollarCentPrettyString(p.prize.amount, true) }
      )}`;
    case PRIZE_TYPES.playerRankOnTeam:
      return `#${translate(
        {
          defaultMessage: `{rank} player on team`,
          serverLocale: p.locale,
          description: "used to show the player where they rank on the team"
        },
        { rank: p.prize.amount }
      )}`;
    case PRIZE_TYPES.playerRankInOrg:
      return `#${translate(
        {
          defaultMessage: `{rank} player in club`,
          serverLocale: p.locale,
          description: "used to show the player where they rank in the club"
        },
        { rank: p.prize.amount }
      )}`;
    case PRIZE_TYPES.teamAmount:
      return `${translate(
        { defaultMessage: `Raise {amount} as team`, serverLocale: p.locale, description: "amount raised" },
        { amount: formatMoneyCentsToDollarCentPrettyString(p.prize.amount, true) }
      )}`;
    case PRIZE_TYPES.teamRankInOrg:
      return `#${translate(
        {
          defaultMessage: `{rank} team in club`,
          serverLocale: p.locale,
          description: "used to show the team where they rank in the club"
        },
        { rank: p.prize.amount }
      )}`;
    case PRIZE_TYPES.referrals:
      return `${translate(
        {
          defaultMessage: `Refer {amount} people to Flex/Ollie`,
          serverLocale: p.locale,
          description: "used to show how many referrals"
        },
        { amount: p.prize.amount }
      )}`;
  }
}

export function orderPrizes(p: { prizes: PrizePlayerStatusDetails[] }) {
  const PRIZE_ORDER = {
    [PRIZE_TYPES.pageViews]: 0,
    [PRIZE_TYPES.referrals]: 1,
    [PRIZE_TYPES.playerAmount]: 2,
    [PRIZE_TYPES.playerRankOnTeam]: 3,
    [PRIZE_TYPES.playerRankInOrg]: 4,
    [PRIZE_TYPES.teamAmount]: 5,
    [PRIZE_TYPES.teamRankInOrg]: 6
  };

  return _.orderBy(p.prizes, [a => PRIZE_ORDER[a.prize.type], a => a.prize.amount], ["asc", "asc"]);
}

export function isPlayerPrize(prize: FundraisingPrize) {
  return (
    prize.type === PRIZE_TYPES.pageViews ||
    prize.type === PRIZE_TYPES.playerAmount ||
    prize.type === PRIZE_TYPES.playerRankInOrg ||
    prize.type === PRIZE_TYPES.playerRankOnTeam ||
    prize.type === PRIZE_TYPES.referrals
  );
}

export function doesRaffleExist(prizes: FundraisingPrize[]) {
  return !!prizes.find(prize => isRafflePrize(prize));
}

export function getActiveTeamFundraisersAsAStaffMember(p: {
  userTeams: Team[];
  fundraisers: FundraiserBasicData[];
  selfAccountId: AccountId;
}) {
  const teamAndFudraiserData: { team: Team; fundraiserData: FundraiserBasicData }[] = [];
  p.fundraisers.forEach(f => {
    if (f.type === "team" && f.fundraiserStatus === "active") {
      const team = p.userTeams.find(t => t.id === f.teamId);
      if (team && team.accounts?.[p.selfAccountId]?.staffTitle) {
        teamAndFudraiserData.push({
          team,
          fundraiserData: f
        });
      }
    }
  });

  return teamAndFudraiserData;
}
